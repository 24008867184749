import commonFormStyles from "./FormCommon.module.css"
import { FieldError } from "react-hook-form"

export const renderFormValidationClassNames = (
  validation: FieldError | undefined,
  additionalClasses?: Array<string>,
) => {
  let classNames = Array<string>()
  classNames.push("form-control")
  classNames.push(commonFormStyles.formInput)
  if (validation == null) {
    classNames.push(commonFormStyles.formInputSuccess)
  } else {
    classNames.push(commonFormStyles.formInputError)
    classNames.push("is-invalid")
  }

  if (additionalClasses !== undefined) {
    classNames = classNames.concat(additionalClasses)
  }

  return classNames.join(" ")
}
