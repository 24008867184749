import * as React from "react"
import styles from "../../forms/registration/RegistrationForm.module.css"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  updateStatus,
  updateOrgType,
  selectOrganizationType,
  selectRegistrationStatus,
} from "./registrationSlice"
import { useRef } from "react"
import {
  OrganizationSelectionForm,
  OrganizationSelectionRef,
} from "../../forms/organization-selection/OrganizationSelectionForm"
import {
  RegistrationForm,
  RegistrationFormRef,
} from "../../forms/registration/RegistrationForm"
import { usePostRegistrationMutation } from "../../api/EdithCmsApi"
import { RegistrationResponse } from "./RegistrationResponse"
import { Navigate } from "react-router-dom"
import { Loading } from "../loading/Loading"

export function Registration() {
  const dispatch = useAppDispatch()
  const status = useAppSelector(selectRegistrationStatus)
  const orgType = useAppSelector(selectOrganizationType)
  const [postRegistration, response] = usePostRegistrationMutation()
  const refOrgSelection = useRef<OrganizationSelectionRef>({
    HandleOrganizationSelection: (orgType) => {
      dispatch(updateOrgType(orgType))
    },
  })
  const refRegistration = useRef<RegistrationFormRef>({
    HandleRegistration: (registration) => {
      postRegistration(registration)
    },
    HandleBackButton: () => {
      dispatch(updateStatus("orgSelection"))
    },
  })

  if (response.isSuccess) {
    const typedResponse = response.data as RegistrationResponse
    if (typedResponse.registrationSuccess) {
      return <Navigate to="/login" />
    }
  }

  if (response.isLoading) {
    return <Loading />
  }

  if (status == "orgSelection") {
    return <OrganizationSelectionForm submitRef={refOrgSelection.current} />
  }

  return (
    <RegistrationForm
      registrationRef={refRegistration.current}
      orgType={orgType}
      registrationErrors={
        response.isSuccess && response.data != undefined
          ? (response.data as RegistrationResponse).errors
          : undefined
      }
    />
  )
}
