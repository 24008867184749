import * as Yup from "yup"

const NoEmailAddressMessage = "Please provide an email address."
const InvalidEmailAddressMessage = "Please provide a valid email address."
const NoDescriptionMessage = "Please provide a description of your issue."

export const supportRequestValidation = Yup.object().shape({
  userEmailAddress: Yup.string()
    .required(NoEmailAddressMessage)
    .email(InvalidEmailAddressMessage),
  userSupportRequest: Yup.string().required(NoDescriptionMessage),
})
