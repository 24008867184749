import { ArticleModel } from "./ArticleModel"

export interface ArticleProperties {
  article: ArticleModel
}

export function Article(props: ArticleProperties) {
  return (
    <div
      className="modulePartContent"
      dangerouslySetInnerHTML={{ __html: props.article.ckEditorHtmlBody.html }}
    ></div>
  )
}
