import { QuestionDetailModel } from "../../module-parts/activity/QuestionDetailModel"
import { FreeResponseActivity } from "../free-response-activity/FreeResponse"
import { MultipleChoiceActivity } from "../multiple-choice-activity/MultipleChoiceActivity"
import { QuestionResponse } from "../responses/QuestionResponse"
import { ActivityResponse } from "../responses/ActivityResponse"
import { Button } from "reactstrap"
import { InternalActivityDetailModel } from "../../module-parts/activity/InternalActivityDetailModel"
import React from "react"
import { UpdateActivityModel } from "../../UpdateActivityModel"
import { ActivityResponseModel } from "../../ActivityResponseModel"
import { usePostActivityResponsesMutation } from "../../../../api/EdithCmsApi"
import $ from "jquery"

export interface InternalActivityProperties {
  activityContentItemId: string
  activityContentItemVersionId: string
  internalActivityView: InternalActivityDetailModel
  responses: ActivityResponseModel[]
}

export function InternalActivity(props: InternalActivityProperties) {
  const [postActivityResponse, result] = usePostActivityResponsesMutation()

  function handleSave(
    activityContentItemId: string,
    activityContentItemVersionId: string,
  ) {
    let activityDiv = $(
      "." + activityContentItemId + activityContentItemVersionId,
    )

    let freeResponseDivs = activityDiv.find(".freeResponse")

    let responses: QuestionResponse[] = []

    freeResponseDivs.map((i, element) => {
      // @ts-ignore
      let questionId = $(element).find("input[type=hidden]")[0].value
      let response = $(element).find("textarea").val()

      let responseText = ""
      if (response) {
        responseText = response.toString()
      }

      responses.push({
        questionContentItemId: questionId,
        questionType: "internal.freeResponse",
        freeResponse: responseText,
        multiChoiceQuestionContentItemId: "",
        multiChoiceQuestionContentVersionItemId: "",
      })
    })

    let updatePayload: UpdateActivityModel = {
      activityContentItemId: props.activityContentItemId,
      activityContentItemVersionId: props.activityContentItemVersionId,
      activityType: "internal",
      responseJson: responses,
    }

    postActivityResponse(updatePayload)
  }

  function renderQuestion(detail: QuestionDetailModel, key: number) {
    if (props.responses.length > 0) {
      //debugger;
    }

    let response = props.responses.find(
      (ar: ActivityResponse) =>
        ar.activityContentItemId == props.activityContentItemId &&
        ar.activityContentItemVersionId == props.activityContentItemVersionId,
    )

    let questionResponse = undefined

    if (response && response.responseJson != undefined) {
      questionResponse = response.responseJson.find(
        (qd: QuestionResponse) =>
          qd.questionContentItemId == detail.internalActivityId,
      )
      //debugger;
    }

    switch (detail.type) {
      case "FreeResponse":
        return (
          <FreeResponseActivity
            key={key}
            questionId={detail.internalActivityId}
            response={questionResponse}
            freeResponseModel={detail.freeResponseActivity}
          ></FreeResponseActivity>
        )
      case "MultipleChoice":
        return (
          <MultipleChoiceActivity
            key={key}
            questionId={detail.internalActivityId}
            response={questionResponse}
            multipleChoiceModel={detail.multipleChoiceActivity}
          ></MultipleChoiceActivity>
        )
    }
  }

  function handleButtonDownOnSave(
    event: React.KeyboardEvent<HTMLButtonElement>,
    activityContentItemId: string,
    activityContentItemVersionId: string,
  ) {
    if (event.key === " " || event.key === "Enter") {
      handleSave(activityContentItemId, activityContentItemVersionId)
    }
  }

  return (
    <>
      <div
        className={
          props.activityContentItemId + props.activityContentItemVersionId
        }
      >
        {props.internalActivityView.questions.map((detail, i) => {
          return renderQuestion(detail, i)
        })}
      </div>
      <Button
        color="success"
        className="save-response-button"
        onClick={() =>
          handleSave(
            props.activityContentItemId,
            props.activityContentItemVersionId,
          )
        }
        onKeyDown={(event) =>
          handleButtonDownOnSave(
            event,
            props.activityContentItemId,
            props.activityContentItemVersionId,
          )
        }
      >
        Save
      </Button>
    </>
  )
}
