import { QualtricsSurveyModel } from "./QualtricsSurveyModel"

export interface QualtricsSurveyViewProperties {
  qualtricsSurvey: QualtricsSurveyModel
}

export function QualtricsSurvey(props: QualtricsSurveyViewProperties) {
  return (
    <iframe className="surveyViewer" src={props.qualtricsSurvey.surveyLink} />
  )
}
