import { PhoneNumberModel } from "./PhoneNumberModel"
import { usePostProfilePhoneNumberMutation } from "../../api/EdithCmsApi"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { profilePhoneNumberValidation } from "./validation"
import { ProgramError } from "../../features/program-error/ProgramError"
import React from "react"
import { Col, FormGroup, Label, Row } from "reactstrap"
import { renderFormValidationClassNames } from "../common/FormCommonFunctions"
import commonFormStyles from "../common/FormCommon.module.css"

export const ProfilePhoneNumberForm = ({
  defaultData,
}: {
  defaultData: PhoneNumberModel
}) => {
  const [postProfilePhoneNumber, result] = usePostProfilePhoneNumberMutation()

  const onSubmit = (data: PhoneNumberModel) => {
    postProfilePhoneNumber(data)
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultData,
    resolver: yupResolver(profilePhoneNumberValidation),
  })
  if (result.isError) {
    return <ProgramError error={result.error} />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <FormGroup>
            <Label for="number">Phone Number</Label>
            <input
              type="text"
              {...register("number")}
              className={renderFormValidationClassNames(errors.number)}
            />
            <div className="invalid-feedback">{errors.number?.message}</div>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="phoneNumberTypeId">Phone Number Type</Label>
            <div className="inputWrapper">
              <select
                placeholder="Select your phone type"
                {...register("phoneNumberTypeId")}
                className={renderFormValidationClassNames(
                  errors.phoneNumberTypeId,
                  ["form-select", "state-select"],
                )}
              >
                <option value="1">Mobile</option>
                <option value="2">Landline</option>
              </select>
              <div className="invalid-feedback">
                {errors.phoneNumberTypeId?.message}
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <input type="checkbox" {...register("isSmsOkay")} />
            <Label
              for="isSmsOkay"
              className={commonFormStyles.formCheckboxLabel}
            >
              Are SMS messages okay at this number?
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <button
            type="submit"
            className={`btn btn-primary ${commonFormStyles.formPullRight}`}
          >
            Update
          </button>
        </Col>
      </Row>
    </form>
  )
}
