import logoImage from "../../images/animated-edith-logo.png"

export function Loading() {
  return (
    <img
      alt="Loading Content"
      className="d-block mx-auto loader"
      src={logoImage}
    />
  )
}
