import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface RegistrationState {
  status: "orgSelection" | "personalDetails"
  orgType: "individual" | "multi-user"
}

const initialState: RegistrationState = {
  status: "orgSelection",
  orgType: "individual",
}

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    updateOrgType: (
      state,
      action: PayloadAction<"individual" | "multi-user">,
    ) => {
      state.status = "personalDetails"
      state.orgType = action.payload
    },
    updateStatus: (
      state,
      action: PayloadAction<"orgSelection" | "personalDetails">,
    ) => {
      state.status = action.payload
    },
  },
})

export const { updateOrgType, updateStatus } = registrationSlice.actions

export const selectRegistrationStatus = (state: RootState) =>
  state.registration.status

export const selectOrganizationType = (state: RootState) =>
  state.registration.orgType

export default registrationSlice.reducer
