import { BaseQueryApi, createApi } from "@reduxjs/toolkit/query/react"
import { RootState } from "../app/store"
import { ActivityResponseRequestModel } from "../features/module-viewer/ActivityResponseRequestModel"
import { ActivityResponseModel } from "../features/module-viewer/ActivityResponseModel"
import { ModuleRequestModel } from "../features/module-viewer/ModuleRequestModel"
import { RegistrationModel } from "../forms/registration/RegistrationModel"
import { SupportRequestModel } from "../forms/support-request/supportRequestModel"
import {
  logout,
  updateTokens,
} from "../features/authentication/authenticationSlice"
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react"
import { BasicProfileModel } from "../forms/basic-profile/BasicProfileModel"
import { AuthenticationTokenModel } from "../features/authentication/authenticationTokenModel"
import { ProfileAddressModel } from "../forms/profile-addresses/ProfileAddressModel"
import { PhoneNumberModel } from "../forms/profile-phone-number/PhoneNumberModel"
import { ModuleCompletionRequest } from "../features/shared/ModuleCompletionRequest"
import { ModuleProgressRequest } from "../features/shared/ModuleProgressRequest"
import { ModuleProgressUpdateRequest } from "../features/shared/ModuleProgressUpdateRequest"
import { ActivityResponseRequestWrapper } from "../features/module-viewer/ActivityResponseRequestWrapper"
import { UpdateActivityModel } from "../features/module-viewer/UpdateActivityModel"
import { PasswordResetRequestModel } from "../features/password-reset/PasswordResetRequestModel"
import { PaymentRequest } from "../features/upgrade/PaymentRequest"

export const unauthenticatedQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_EDITH_CMS_ROOT_URI}/`,
})

export const apiQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_EDITH_CMS_ROOT_URI}/`,
  prepareHeaders: async (headers, { getState }) => {
    const state = getState() as RootState
    if (state.authentication.status === "logged-in") {
      headers.set(
        "Authorization",
        `Bearer ${state.authentication.tokens.access_token}`,
      )
    }
    headers.set("Content-Type", "application/json")
    return headers
  },
})

const apiQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await apiQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    let state = api.getState() as RootState
    let refreshToken = state.authentication.tokens.refresh_token
    let bodyData = new Array<string>()

    pushUrlEncodedFormProperty(
      bodyData,
      "grant_type",
      import.meta.env.VITE_EDITH_CMS_CLIENT_GRANT_TYPE_REFRESH_TOKEN,
    )
    pushUrlEncodedFormProperty(
      bodyData,
      "client_id",
      import.meta.env.VITE_EDITH_CMS_CLIENT_ID,
    )
    pushUrlEncodedFormProperty(
      bodyData,
      "client_secret",
      import.meta.env.VITE_EDITH_CMS_CLIENT_SECRET,
    )
    pushUrlEncodedFormProperty(bodyData, "refresh_token", refreshToken)

    //debugger
    // try to get a new token
    const refreshResult = await authenticationQuery(
      {
        headers: {},
        method: "POST",
        url: "",
        body: bodyData.join("&"),
      },
      api,
      extraOptions,
    )
    if (refreshResult.data) {
      // store the new token
      api.dispatch(updateTokens(refreshResult.data as AuthenticationTokenModel))
      // retry the initial query
      result = await apiQuery(args, api, extraOptions)
    } else {
      await api.dispatch(logout())
    }
  }
  return result
}

const authenticationQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_EDITH_CMS_ROOT_URI}/connect/token`,
  prepareHeaders: (headers: Headers) => {
    headers.set(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8",
    )
    return headers
  },
})

function pushUrlEncodedFormProperty(
  formData: Array<string>,
  key: string,
  value: string,
) {
  const encodedKey = encodeURIComponent(key)
  const encodedValue = encodeURIComponent(value)
  formData.push(`${encodedKey}=${encodedValue}`)
}

export const AuthenticationApi = createApi({
  reducerPath: "authenticationApi",
  baseQuery: authenticationQuery,
  endpoints: (builder) => ({
    getAccessToken: builder.mutation<
      any,
      { username: string; password: string }
    >({
      query: (arg) => {
        const { username, password } = arg
        let bodyData = new Array<string>()
        pushUrlEncodedFormProperty(
          bodyData,
          "grant_type",
          import.meta.env.VITE_EDITH_CMS_CLIENT_GRANT_TYPE_PASSWORD,
        )
        pushUrlEncodedFormProperty(
          bodyData,
          "client_id",
          import.meta.env.VITE_EDITH_CMS_CLIENT_ID,
        )
        pushUrlEncodedFormProperty(
          bodyData,
          "client_secret",
          import.meta.env.VITE_EDITH_CMS_CLIENT_SECRET,
        )
        pushUrlEncodedFormProperty(
          bodyData,
          "scope",
          import.meta.env.VITE_EDITH_CMS_CLIENT_SCOPES,
        )
        pushUrlEncodedFormProperty(bodyData, "username", username)
        pushUrlEncodedFormProperty(bodyData, "password", password)

        return {
          url: "",
          method: "POST",
          prepareHeaders: (headers: Headers) => {
            headers.set(
              "Content-Type",
              "application/x-www-form-urlencoded;charset=UTF-8",
            )
            return headers
          },
          body: bodyData.join("&"),
        }
      },
    }),
    refreshAccessToken: builder.mutation<any, string>({
      query: (refreshToken) => {
        let bodyData = new Array<string>()
        pushUrlEncodedFormProperty(
          bodyData,
          "grant_type",
          import.meta.env.VITE_EDITH_CMS_CLIENT_GRANT_TYPE_REFRESH_TOKEN,
        )
        pushUrlEncodedFormProperty(
          bodyData,
          "client_id",
          import.meta.env.VITE_EDITH_CMS_CLIENT_ID,
        )
        pushUrlEncodedFormProperty(
          bodyData,
          "client_secret",
          import.meta.env.VITE_EDITH_CMS_CLIENT_SECRET,
        )
        pushUrlEncodedFormProperty(bodyData, "refresh_token", refreshToken)

        return {
          url: "/connect/token",
          method: "POST",
          prepareHeaders: (headers: Headers) => {
            headers.set(
              "Content-Type",
              "application/x-www-form-urlencoded;charset=UTF-8",
            )
            return headers
          },
          body: bodyData.join("&"),
        }
      },
    }),
  }),
})

export const { useGetAccessTokenMutation, useRefreshAccessTokenMutation } =
  AuthenticationApi

export const EdithCmsApi = createApi({
  reducerPath: "edithCmsApi",
  baseQuery: unauthenticatedQuery,
  endpoints: (builder) => ({
    getEula: builder.query({
      query: () => {
        return {
          url: "/api/eula/",
          method: "GET",
        }
      },
    }),
    getHomePage: builder.query({
      query: () => {
        return {
          url: "/api/homepage/",
          method: "GET",
        }
      },
    }),
    getFaq: builder.query({
      query: () => {
        return {
          url: "/api/faq",
          method: "GET",
        }
      },
    }),
    getPrivacyPolicy: builder.query({
      query: () => {
        return {
          url: "/api/privacy-policy/",
          method: "GET",
        }
      },
    }),
    postRegistration: builder.mutation({
      query: (registration: RegistrationModel) => {
        return {
          url: "/api/register",
          method: "POST",
          body: registration,
        }
      },
    }),
    postSupportRequest: builder.mutation({
      query: (supportRequest: SupportRequestModel) => {
        return {
          url: "/api/templated-email",
          method: "POST",
          body: supportRequest,
        }
      },
    }),
    postStartPasswordRest: builder.mutation({
      query: (email: string) => {
        return {
          url: "/api/templated-email",
          method: "POST",
          body: {
            Email: email,
          },
        }
      },
    }),
    getPasswordResetRequest: builder.query({
      query: (requestId: string) => {
        return {
          url: `/api/reset-password/${requestId}`,
          method: "GET",
        }
      },
    }),
    postPasswordResetRequest: builder.mutation({
      query: (request: PasswordResetRequestModel) => {
        return {
          url: `/api/reset-password/update/${request.requestId}`,
          method: "PUT",
          body: {
            Password: request.password,
          },
        }
      },
    }),
  }),
})

export const {
  usePostSupportRequestMutation,
  usePostRegistrationMutation,
  useGetFaqQuery,
  useGetPrivacyPolicyQuery,
  useGetHomePageQuery,
  useGetEulaQuery,
  usePostStartPasswordRestMutation,
  useGetPasswordResetRequestQuery,
  usePostPasswordResetRequestMutation,
} = EdithCmsApi

export const AuthenticatedEdithCmsApi = createApi({
  reducerPath: "authenticatedEdithCmsApi",
  baseQuery: apiQueryWithReAuth,
  tagTypes: [
    "BasicProfile",
    "ProfileAddresses",
    "ProfilePhoneNumber",
    "ModuleCompletions",
    "ModuleProgress",
    "ActivityResponses",
    "Account",
  ],
  endpoints: (builder) => ({
    getBasicProfile: builder.query({
      providesTags: ["BasicProfile"],
      query: () => {
        return {
          url: "edith-api/profile/",
          method: "GET",
        }
      },
    }),
    postBasicProfile: builder.mutation({
      invalidatesTags: ["BasicProfile"],
      query: (basicProfile: BasicProfileModel) => {
        return {
          url: "edith-api/profile/",
          method: "POST",
          body: basicProfile,
        }
      },
    }),
    getProfileAddresses: builder.query({
      providesTags: ["ProfileAddresses"],
      query: () => {
        return {
          url: "edith-api/profile/addresses",
          method: "GET",
        }
      },
    }),
    postProfileAddresses: builder.mutation({
      invalidatesTags: ["ProfileAddresses"],
      query: (profileAddresses: ProfileAddressModel) => {
        return {
          url: "edith-api/profile/addresses",
          method: "POST",
          body: profileAddresses,
        }
      },
    }),
    getProfilePhoneNumber: builder.query({
      providesTags: ["ProfilePhoneNumber"],
      query: () => {
        return {
          url: "edith-api/profile/phonenumber",
          method: "GET",
        }
      },
    }),
    postProfilePhoneNumber: builder.mutation({
      invalidatesTags: ["ProfilePhoneNumber"],
      query: (profilePhoneNumber: PhoneNumberModel) => {
        return {
          url: "edith-api/profile/phonenumber",
          method: "POST",
          body: profilePhoneNumber,
        }
      },
    }),
    getModuleCompletions: builder.query({
      providesTags: ["ModuleCompletions"],
      query: () => {
        return {
          url: "edith-api/module-completion",
          method: "GET",
        }
      },
    }),
    postModuleCompletion: builder.mutation({
      invalidatesTags: ["ModuleCompletions"],
      query: (request: ModuleCompletionRequest) => {
        return {
          url: `/edith-api/module-completion/${request.moduleContentItemId}/${request.moduleContentItemVersionId}/${request.completed}`,
          method: "POST",
        }
      },
    }),
    getModuleProgress: builder.query({
      providesTags: ["ModuleProgress"],
      query: (request: ModuleProgressRequest) => {
        return {
          url: `edith-api/module-progress/${request.contentItemId}/${request.contentItemVersionId}`,
          method: "GET",
        }
      },
    }),
    postModuleProgress: builder.mutation({
      invalidatesTags: ["ModuleProgress"],
      query: (request: ModuleProgressUpdateRequest) => {
        return {
          url: `edith-api/module-progress/${request.contentItemId}/${request.contentItemVersionId}/${request.progress}`,
          method: "POST",
        }
      },
    }),
    getActivityResponses: builder.query({
      providesTags: ["ActivityResponses"],
      query: (request: ActivityResponseRequestWrapper) => {
        return {
          url: "edith-api/activity-response/get-many",
          method: "POST",
          body: JSON.stringify(request.requests),
        }
      },
    }),
    postActivityResponses: builder.mutation({
      invalidatesTags: ["ActivityResponses"],
      query: (request: UpdateActivityModel) => {
        return {
          url: "edith-api/activity-response/",
          method: "POST",
          body: request,
        }
      },
    }),
    getAccountMeta: builder.query({
      providesTags: ["Account"],
      query: () => {
        return {
          url: "edith-api/account-management/account-meta",
          method: "GET",
        }
      },
    }),
    postPayment: builder.mutation({
      invalidatesTags: ["Account"],
      query: (request: PaymentRequest) => {
        return {
          url: "edith-api/account-management/make-payment",
          method: "POST",
          body: request,
        }
      },
    }),
  }),
})

export const {
  useGetBasicProfileQuery,
  usePostBasicProfileMutation,
  useGetProfileAddressesQuery,
  usePostProfileAddressesMutation,
  useGetProfilePhoneNumberQuery,
  usePostProfilePhoneNumberMutation,
  useGetModuleCompletionsQuery,
  usePostModuleCompletionMutation,
  useGetModuleProgressQuery,
  usePostModuleProgressMutation,
  useGetActivityResponsesQuery,
  usePostActivityResponsesMutation,
  useGetAccountMetaQuery,
  usePostPaymentMutation,
} = AuthenticatedEdithCmsApi

export const GraphQlApi = createApi({
  reducerPath: "graphQlApi",
  baseQuery: apiQueryWithReAuth,
  refetchOnMountOrArgChange: 900,
  endpoints: (builder) => ({
    getModulesListing: builder.query({
      query: () => {
        return {
          url: "/api/graphql",
          method: "POST",
          body: {
            query: `query Modules { 
    module {
        contentModuleSequence {
            sequence
        }    
        contentItemId
        createdUtc
        publishedUtc
        ckEditorHtmlBody {
            html
        }
        contentModule {
            moduleName
        }
        published
        latest
        contentItemVersionId
        author
        paidContent
        coverImage {
            urls
        }
    }
}`,
            variables: {},
          },
        }
      },
    }),
    getModuleDetail: builder.query({
      query: (request: ModuleRequestModel) => {
        return {
          url: "/api/graphql",
          method: "POST",
          body: {
            query: `query ModuleDetail($targetContentItemId: String, $contentItemId: ID) {
  module(where: {contentItemId: $contentItemId}) {
    contentType
    displayText
    author
    createdUtc
    publishedUtc
    publishedUtc
    contentItemId
    contentItemVersionId
    ckEditorHtmlBody {
      html
    }
    contentModule {
      moduleName
    }
    contentModuleSequence {
      sequence
    }
  }
  activity(where: {contentModulePicker: {moduleContentItemId: $targetContentItemId}}) {
    contentType
    displayText
    author
    createdUtc
    publishedUtc
    publishedUtc
    contentItemId
    contentItemVersionId
    contentModulePicker {
      moduleContentItemId
    }
    contentModuleSequence {
      sequence
    }
    activity {
      externalActivity {
        uri
      }
      internalActivity {
        questions {
          freeResponseActivity {
            question
          }
          multipleChoiceActivity {
            answers {
              answer
              order
            }
            question
          }
          type
          internalActivityId
        }
      }
      activityType
    }
  }
  article(where: {contentModulePicker: {moduleContentItemId: $targetContentItemId}}) {
    contentType
    displayText
    author
    createdUtc
    publishedUtc
    publishedUtc
    contentItemId
    contentItemVersionId
    contentModulePicker {
      moduleContentItemId
    }
    contentModuleSequence {
      sequence
    }
    ckEditorHtmlBody {
      html
    }
  }
  definition(where: {contentModulePicker: {moduleContentItemId: $targetContentItemId}}) {
    contentType
    displayText
    author
    createdUtc
    publishedUtc
    publishedUtc
    contentItemId
    contentItemVersionId
    contentModulePicker {
      moduleContentItemId
    }
    contentModuleSequence {
      sequence
    }
    ckEditorHtmlBody {
      html
    }
  }
  summary(where: {contentModulePicker: {moduleContentItemId: $targetContentItemId}}) {
    contentType
    displayText
    author
    createdUtc
    publishedUtc
    publishedUtc
    contentItemId
    contentItemVersionId
    contentModulePicker {
      moduleContentItemId
    }
    contentModuleSequence {
      sequence
    }
    ckEditorHtmlBody {
      html
    }
  }
  video(where: {contentModulePicker: {moduleContentItemId: $targetContentItemId}}) {
    contentType
    displayText
    author
    createdUtc
    publishedUtc
    publishedUtc
    contentItemId
    contentItemVersionId
    contentModulePicker {
      moduleContentItemId
    }
    contentModuleSequence {
      sequence
    }
    ckEditorHtmlBody {
      html
    }
  }
  visual(where: {contentModulePicker: {moduleContentItemId: $targetContentItemId}}) {
    contentType
    displayText
    author
    createdUtc
    publishedUtc
    publishedUtc
    contentItemId
    contentItemVersionId
    contentModulePicker {
      moduleContentItemId
    }
    contentModuleSequence {
      sequence
    }
    ckEditorHtmlBody {
      html
    }
  }
  qualtricsSurvey(where: {contentModulePicker: {moduleContentItemId: $targetContentItemId}}) {
    latest
    contentModulePicker {
      moduleContentItemId
    }
    contentModuleSequence {
      sequence
    }
    contentType
    displayText
    author
    createdUtc
    publishedUtc
    publishedUtc
    contentItemId
    contentItemVersionId
    surveyLink
  }
}`,
            variables: {
              targetContentItemId: request.moduleId,
              contentItemId: request.moduleId,
            },
          },
        }
      },
    }),
    getHandoutListing: builder.query({
      query: () => {
        return {
          url: "/api/graphql",
          method: "POST",
          body: {
            query: `query Handouts {
  handout {
    contentItemId
    contentItemVersionId
    contentType
    createdUtc
    displayText
    downloadUrl {
      urls
    }
    latest
    modifiedUtc
    paidContent
    published
    publishedUtc
    thumbnail {
      urls
    }
  }
}`,
            variables: {},
          },
        }
      },
    }),
  }),
})

export const {
  useGetModuleDetailQuery,
  useGetModulesListingQuery,
  useGetHandoutListingQuery,
} = GraphQlApi
