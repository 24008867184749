import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store, persistor } from "./app/store"
import { createBrowserRouter, RouterProvider, useRouteError } from "react-router-dom"
import { HomePage } from "./features/home-page/HomePage"
import { ProgramError } from "./features/program-error/ProgramError"
import { PersistGate } from "redux-persist/integration/react"
import { Loading } from "./features/loading/Loading"
import { Layout } from "./features/layout/Layout"
import "./ApplicationStyle.scss"
import "./index.css"
import { Eula } from "./features/eula/Eula"
import { PrivacyPolicy } from "./features/privacy-policy/PrivacyPolicy"
import { SupportRequest } from "./features/support-request/SupportRequest"
import { Login } from "./features/login/Login"
import { Registration } from "./features/registration/Registration"
import { Profile } from "./features/profile/Profile"
import { ModuleSelection } from "./features/module-selection/ModuleSelection"
import { ModuleViewer } from "./features/module-viewer/ModuleViewer"
import { RequestPasswordReset } from "./features/request-reset/RequestPasswordReset"
import { PasswordReset } from "./features/password-reset/PasswordReset"
import { Upgrade } from "./features/upgrade/Upgrade"
import { Faq } from "./features/faq/Faq"
import { Handouts } from "./features/handouts/Handouts"

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/eula",
    element: <Eula />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/support-request",
    element: <SupportRequest />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/registration",
    element: <Registration />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/profile",
    element: <Profile />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/module-selection",
    element: <ModuleSelection />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/module-viewer/:contentModuleItemId/:contentModuleItemVersionId",
    element: <ModuleViewer />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/reset-password-reset",
    element: <RequestPasswordReset />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/setnewpassword/:passwordResetRequestId",
    element: <PasswordReset />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/upgrade",
    element: <Upgrade />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/faq",
    element: <Faq />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/resources",
    element: <Handouts />,
    errorElement: <ErrorBoundary />,
  },
])

function ErrorBoundary() {
  let error = useRouteError()
  return <ProgramError error={error} />
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Layout>
          <RouterProvider router={router} />
        </Layout>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
