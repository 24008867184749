import { FreeResponseModel } from "./FreeResponseModel"
import { QuestionResponse } from "../responses/QuestionResponse"
import { FormGroup, Input, Label } from "reactstrap"

export interface FreeResponseActivityProperties {
  questionId: string
  freeResponseModel: FreeResponseModel
  response: QuestionResponse | undefined
}

export function FreeResponseActivity(props: FreeResponseActivityProperties) {
  function getQuestionId(response: QuestionResponse | undefined) {
    if (response == undefined) {
      return ""
    }

    return response.questionContentItemId
  }

  function getResponse(response: QuestionResponse | undefined) {
    if (response == undefined) {
      return ""
    }

    return response.freeResponse
  }

  return (
    <div id={getQuestionId(props.response)} className="freeResponse">
      <Input
        type="hidden"
        className="questionId"
        value={props.questionId}
        aria-hidden="true"
      />
      <FormGroup>
        <p>
          <b>Question:</b>
        </p>
        <p className="freeResponseQuestion">
          {props.freeResponseModel.question}
        </p>
      </FormGroup>
      <FormGroup>
        <b>
          <Label for={`response-${props.questionId}`}>My Response:</Label>
        </b>
        <Input
          id={`response-${props.questionId}`}
          type="textarea"
          defaultValue={getResponse(props.response)}
          tabIndex={0}
        />
      </FormGroup>
    </div>
  )
}
