import { VideoModel } from "./VideoModel"

export interface VideoProperties {
  video: VideoModel
}

export function Video(props: VideoProperties) {
  return (
    <div
      className="modulePartContent"
      dangerouslySetInnerHTML={{
        __html: props.video.ckEditorHtmlBody.html,
      }}
    ></div>
  )
}
