import { useAppSelector, useAppDispatch } from "../../app/hooks"
import {
  selectSessionStatus,
  logout,
} from "../authentication/authenticationSlice"
import {
  Collapse,
  Container,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap"
import { useState } from "react"
import "./NavMenu.css"
import {
  AuthenticatedEdithCmsApi,
  AuthenticationApi,
  EdithCmsApi,
  GraphQlApi,
  useGetAccountMetaQuery,
} from "../../api/EdithCmsApi"
import { AccountMeta } from "../shared/AccountMeta"
import { Navigate } from "react-router-dom"

export function NavMenu() {
  const sessionStatus = useAppSelector(selectSessionStatus)
  const [isOpen, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const {
    data: accountMetaData,
    isError: accountMetaIsError,
    isLoading: accountMetaIsLoading,
    error: accountMetaError,
  } = useGetAccountMetaQuery(
    {},
    {
      skip: sessionStatus == "logged-out",
    },
  )

  function handleLogout() {
    dispatch(AuthenticationApi.util?.resetApiState())
    dispatch(EdithCmsApi.util?.resetApiState())
    dispatch(AuthenticatedEdithCmsApi.util?.resetApiState())
    dispatch(GraphQlApi.util?.resetApiState())
    dispatch(logout())

    return <Navigate to="/login" />
  }

  function RenderDependentNavigation() {
    if (sessionStatus === "logged-in") {
      return (
        <>
          <NavItem>
            <NavLink className="text-dark" href="/module-selection">
              Modules
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="text-dark" href="/profile">
              Profile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="text-dark"
              href="#"
              onClick={() => handleLogout()}
            >
              Logout
            </NavLink>
          </NavItem>
        </>
      )
    } else {
      return (
        <NavItem>
          <NavLink className="text-dark" href="/login">
            Login
          </NavLink>
        </NavItem>
      )
    }
  }

  function RenderUpgradeNavigation() {
    if (sessionStatus === "logged-in") {
      const accountMeta = accountMetaData as AccountMeta
      if (accountMeta != undefined) {
        if (accountMeta.accountTypeId == 1 || accountMeta.accountTypeId == 3) {
          if (!accountMeta.isPaidAccount) {
            return (
              <NavItem>
                <NavLink className="text-primary" href="/upgrade">
                  Upgrade
                </NavLink>
              </NavItem>
            )
          }
        }
      }
    }
  }

  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3"
        light
      >
        <Container>
          <NavbarBrand to="/" role="banner">
            <img alt="Edith Institute" src="/edith-logo.png" className="logo" />
          </NavbarBrand>
          <NavbarToggler
            onClick={() => setOpen(!isOpen)}
            className="mr-2 edith-toggler"
          />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse nav-links"
            isOpen={isOpen}
            navbar
          >
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink className="text-dark" href="/">
                  Home
                </NavLink>
              </NavItem>
              {RenderDependentNavigation()}
              <UncontrolledDropdown>
                <DropdownToggle nav caret>
                  More
                </DropdownToggle>
                <DropdownMenu right>
                  <NavItem>
                    <NavLink className="text-dark" href="/faq">
                      F.A.Q.
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="text-dark" href="/resources">
                      Resources
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="text-dark" href="/support-request">
                      Request Help
                    </NavLink>
                  </NavItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {RenderUpgradeNavigation()}
            </ul>
          </Collapse>
        </Container>
      </Navbar>
    </header>
  )
}
