// @ts-ignore
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk"
import styles from "./Upgrade.module.css"
import commonFormStyles from "../../forms/common/FormCommon.module.css"
import { v4 as uuidv4 } from "uuid"
import { useState } from "react"
import { usePostPaymentMutation } from "../../api/EdithCmsApi"
import { ProgramError } from "../program-error/ProgramError"
import { Loading } from "../loading/Loading"
import { PaymentResponse } from "./PaymentResponse"
import { ThankYou } from "./ThankYou"

export function Upgrade() {
  const [idpKey, setIdpKey] = useState(uuidv4())
  const [postPayment, result] = usePostPaymentMutation()

  if (result.isLoading) {
    return <Loading />
  }

  if (result.isError) {
    return <ProgramError error={result.error} />
  }

  if (result.isSuccess) {
    const paymentResponse = result.data as PaymentResponse
    if (paymentResponse.paymentSuccess) {
      return <ThankYou receiptUri={paymentResponse.receiptUri} />
    }
  }

  function RenderErrors() {
    if (result.isSuccess) {
      const paymentResponse = result.data as PaymentResponse
      if (!paymentResponse.paymentSuccess) {
        return (
          <ul>
            {paymentResponse.errors.map((value, index) => {
              return <li>{value}</li>
            })}
          </ul>
        )
      }
    }
  }

  return (
    <>
      <div
        className={`${commonFormStyles.edithFormHeader} ${styles.headerCenter}`}
      >
        Get full access to the course for $39.95
      </div>
      {RenderErrors()}
      <div className={styles.container}>
        <PaymentForm
          applicationId={import.meta.env.VITE_SQUARE_APPLICATION_ID}
          cardTokenizeResponseReceived={async ({
            token,
          }: {
            token: string
          }) => {
            postPayment({
              paymentToken: token,
              idempotencyKey: idpKey,
            })
          }}
          locationId={import.meta.env.VITE_SQUARE_LOCATION_ID}
        >
          <CreditCard />
        </PaymentForm>
      </div>
    </>
  )
}
