import * as Yup from "yup"

export const MaxLength256 = "Please no longer than 256 characters."

export const MailingAddress1ErrorMessage =
  "Please provide a first mailing address line."
export const MailingCityMessage = "Please provide a mailing city name."
export const MailingStateErrorMessage = "Please select a mailing state."
export const MailingZipMessage = "Please provide a mailing zip code."

export const BillingAddress1ErrorMessage =
  "Please provide a first billing address line."
export const BillingCityMessage = "Please provide a billing city name."
export const BillingStateErrorMessage = "Please select a billing state."
export const BillingZipMessage = "Please provide a billing zip code."

export const profileAddressValidation = Yup.object().shape({
  mailingAddress: Yup.object({
    id: Yup.number().required(),
    address1: Yup.string()
      .required(MailingAddress1ErrorMessage)
      .max(256, MaxLength256),
    address2: Yup.string().max(256, MaxLength256),
    city: Yup.string().required(MailingCityMessage),
    state: Yup.string()
      .required(MailingStateErrorMessage)
      .max(2, MailingStateErrorMessage)
      .min(2, MailingStateErrorMessage),
    zipCode: Yup.string().required(MailingZipMessage),
  }),
  billingAddress: Yup.object({
    id: Yup.number().required(),
    address1: Yup.string()
      .required(BillingAddress1ErrorMessage)
      .max(256, MaxLength256),
    address2: Yup.string().max(256, MaxLength256),
    city: Yup.string().required(BillingCityMessage),
    state: Yup.string()
      .required(BillingStateErrorMessage)
      .max(2, BillingStateErrorMessage)
      .min(2, BillingStateErrorMessage),
    zipCode: Yup.string().required(BillingZipMessage),
  }),
  mailingAndBillingIdentical: Yup.boolean().required(),
})
