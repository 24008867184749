import * as Yup from "yup"

export const digitsOnly = (value: string) => /^\d+$/.test(value)
export const PhoneNumberTypeErrorMessage = "Please select a phone number type."
export const PhoneNumberRequiredMessage = "Please provide your phone number."
export const PhoneNumberErrorMessage =
  "Please make sure your phone number is only digits."
export const PhoneNumberLengthMinError =
  "Please make sure your phone number is ten(10) digits."

export const profilePhoneNumberValidation = Yup.object().shape({
  id: Yup.number().required(),
  phoneNumberTypeId: Yup.number()
    .min(1, PhoneNumberTypeErrorMessage)
    .max(2, PhoneNumberTypeErrorMessage)
    .required(PhoneNumberTypeErrorMessage),
  number: Yup.string()
    .test("Digits Only", PhoneNumberErrorMessage, function (value) {
      if (value == undefined) {
        return false
      }

      if (digitsOnly(value)) {
        return true
      }

      return false
    })
    .min(10, PhoneNumberLengthMinError)
    .required(PhoneNumberRequiredMessage),
  isSmsOkay: Yup.boolean().required(),
})
