import "./Footer.module.css"
export function Footer() {
  const year = new Date().getFullYear()

  return (
    <footer>
      <p>Copyright © {year} by Edith Institute</p>
    </footer>
  )
}
