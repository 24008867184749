import React from "react"
import { useForm } from "react-hook-form"
import { RequestResetModel } from "./RequestResetModel"
import { usePostStartPasswordRestMutation } from "../../api/EdithCmsApi"
import { yupResolver } from "@hookform/resolvers/yup"
import { requestPasswordResetValidation } from "./validation"
import { ProgramError } from "../../features/program-error/ProgramError"
import { Col, FormGroup, Label, Row } from "reactstrap"
import commonFormStyles from "../common/FormCommon.module.css"
import { renderFormValidationClassNames } from "../common/FormCommonFunctions"
import { Loading } from "../../features/loading/Loading"
import styles from "./RequestResetForm.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"

export const RequestResetForm = () => {
  const [postResetRequest, result] = usePostStartPasswordRestMutation({})

  const onSubmit = (data: RequestResetModel) => {
    postResetRequest(data.emailAddress)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(requestPasswordResetValidation),
  })

  if (result.isLoading) {
    return <Loading />
  }

  if (result.isError) {
    return <ProgramError error={result.error} />
  }

  if (result.isSuccess) {
    return (
      <div>
        <h1 className={styles.header}>
          <FontAwesomeIcon icon={faPaperPlane} className={styles.plane} />
          Request Submitted
        </h1>
        <p className={styles.message}>
          We've received you're request. If we find your email address in the
          system you will receive an email shortly. If you do not receive an
          email double check that you entered your email address properly.
        </p>
      </div>
    )
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={commonFormStyles.modalForm}
    >
      <div className={commonFormStyles.edithFormHeader}>Password Reset</div>
      <p className={commonFormStyles.requiredFields} />
      <Row>
        <Col>
          <FormGroup>
            <Label for="email" className="required-field-label">
              Email<sup>*</sup>
            </Label>
            <input
              type="text"
              {...register("emailAddress")}
              className={renderFormValidationClassNames(errors.emailAddress)}
            />
            <div className="invalid-feedback">
              {errors.emailAddress?.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <button
            type="submit"
            className={`btn btn-primary ${commonFormStyles.formPullRight}`}
          >
            Request Password Reset
          </button>
        </Col>
      </Row>
    </form>
  )
}
