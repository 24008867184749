import * as Yup from "yup"
import { ref } from "yup"
import {
  ConfirmationNotMatching,
  NoConfirmationPassword,
  NoPasswordMessage,
  PasswordNoLower,
  PasswordNoSpecialCharacters,
  PasswordNotLongEnough,
  PasswordNoUpper,
} from "../set-new-password/validation"
import {
  BillingAddress1ErrorMessage,
  BillingCityMessage,
  BillingStateErrorMessage,
  BillingZipMessage,
  MailingAddress1ErrorMessage,
  MailingCityMessage,
  MailingStateErrorMessage,
  MailingZipMessage,
  MaxLength256,
} from "../profile-addresses/validation"
import {
  digitsOnly,
  PhoneNumberErrorMessage,
  PhoneNumberLengthMinError,
  PhoneNumberRequiredMessage,
  PhoneNumberTypeErrorMessage,
} from "../profile-phone-number/validation"

const UsernameRequiredMessageMessage = "Please provide a username."
const UsernameThreeCharactersMessage =
  "Please provide a username longer than three(3) characters."
const UsernameNoWhiteSpaceMessage =
  "Please provide a username with no whitespace characters."
const OrganizationNameMessage = "Please provide an organization name."
const OrganizationNameLengthMessage =
  "Please provide an organization name longer than three(3) characters."
const EmailRequiredMessage = "Please provide an email."
const ValidEmailRequiredMessage = "Please provide a valid email."
const ConfirmEmailMessage = "Please confirm your email."
const ConfirmEmailNoMatchMessage = "Please make sure your emails match."
const NoFirstNameMessage = "Please provide a first name."
const NoLastNameMessage = "Please provide a last name."
const AgreeToEulaMessage = "Please agree to our EULA."
const AgreeToPrivacyPolicy = "Please agree to our Privacy Policy"

export const registrationValidation = Yup.object().shape({
  accountTypeIsOrg: Yup.boolean().required(),
  username: Yup.string()
    .required(UsernameRequiredMessageMessage)
    .min(3, UsernameThreeCharactersMessage)
    .test(
      "No White Space",
      UsernameNoWhiteSpaceMessage,
      (value) => !/\s/.test(value),
    ),
  email: Yup.string()
    .required(EmailRequiredMessage)
    .email(ValidEmailRequiredMessage),
  confirmEmail: Yup.string()
    .required(ConfirmEmailMessage)
    .oneOf([ref("email")], ConfirmEmailNoMatchMessage),
  password: Yup.string()
    .required(NoPasswordMessage)
    .min(8, PasswordNotLongEnough)
    .matches(
      /((?=.[1234567890-=!@#$%^&*()_+`~[\]{};:"\|,.\/<>?]){1})/,
      PasswordNoSpecialCharacters,
    )
    .matches(/[A-Z]/, PasswordNoUpper)
    .matches(/[a-z]/, PasswordNoLower),
  confirmPassword: Yup.string()
    .required(NoConfirmationPassword)
    .oneOf([ref("password")], ConfirmationNotMatching),
  accountName: Yup.string().when("accountTypeIsOrg", {
    is: true,
    then: (schema) =>
      schema
        .required(OrganizationNameMessage)
        .min(3, OrganizationNameLengthMessage),
  }),
  mailingAddress1: Yup.string()
    .required(MailingAddress1ErrorMessage)
    .max(256, MaxLength256),
  mailingAddress2: Yup.string().max(256, MaxLength256),
  mailingCity: Yup.string().required(MailingCityMessage),
  mailingState: Yup.string()
    .required(MailingStateErrorMessage)
    .max(2, MailingStateErrorMessage)
    .min(2, MailingStateErrorMessage),
  mailingZipCode: Yup.string().required(MailingZipMessage),
  billingAddress1: Yup.string().when("mailingAndBillingIdentical", {
    is: false,
    then: (schema) =>
      schema.required(BillingAddress1ErrorMessage).max(256, MaxLength256),
  }),
  billingAddress2: Yup.string().when("mailingAndBillingIdentical", {
    is: false,
    then: (schema) => schema.max(256, MaxLength256),
  }),
  billingCity: Yup.string().when("mailingAndBillingIdentical", {
    is: false,
    then: (schema) => schema.required(BillingCityMessage),
  }),
  billingState: Yup.string().when("mailingAndBillingIdentical", {
    is: false,
    then: (schema) =>
      schema
        .required(BillingStateErrorMessage)
        .max(2, BillingStateErrorMessage)
        .min(2, BillingStateErrorMessage),
  }),
  billingZipCode: Yup.string().when("mailingAndBillingIdentical", {
    is: false,
    then: (schema) => schema.required(BillingZipMessage),
  }),
  mailingAndBillingIdentical: Yup.boolean().required(),
  phoneNumberTypeId: Yup.number()
    .min(1, PhoneNumberTypeErrorMessage)
    .max(2, PhoneNumberTypeErrorMessage)
    .required(PhoneNumberTypeErrorMessage),
  phoneNumber: Yup.string()
    .required(PhoneNumberRequiredMessage)
    .test("Digits Only", PhoneNumberErrorMessage, function (value) {
      if (value == undefined) {
        return false
      }

      if (digitsOnly(value)) {
        return true
      }

      return false
    })
    .min(10, PhoneNumberLengthMinError),
  isSmsOkay: Yup.boolean().required(),
  firstName: Yup.string().required(NoFirstNameMessage),
  lastName: Yup.string().required(NoLastNameMessage),
  agreeToPrivacyAgreement: Yup.boolean()
    .required(AgreeToEulaMessage)
    .oneOf([true], AgreeToEulaMessage),
  agreeToEula: Yup.boolean()
    .required(AgreeToPrivacyPolicy)
    .oneOf([true], AgreeToPrivacyPolicy),
})
