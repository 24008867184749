import * as Yup from "yup"

const NoEmailAddressErrorMessage = "Please provide an email address."
const EmailAddressErrorMessage = "Please provide a valid email address."

export const requestPasswordResetValidation = Yup.object().shape({
  emailAddress: Yup.string()
    .required(NoEmailAddressErrorMessage)
    .email(EmailAddressErrorMessage),
})
