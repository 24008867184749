import React from "react"
import { usePostSupportRequestMutation } from "../../api/EdithCmsApi"
import { SupportRequestModel } from "./supportRequestModel"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { supportRequestValidation } from "./validation"
import { ProgramError } from "../../features/program-error/ProgramError"
import { Loading } from "../../features/loading/Loading"
import commonFormStyles from "../common/FormCommon.module.css"
import { Col, FormGroup, Label, Row } from "reactstrap"
import { renderFormValidationClassNames } from "../common/FormCommonFunctions"

export const SupportRequestForm = () => {
  const [postSupportRequest, result] = usePostSupportRequestMutation()

  const onSubmit = (data: SupportRequestModel) => {
    postSupportRequest(data)
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(supportRequestValidation),
  })

  if (result.isError) {
    return <ProgramError error={result.error} />
  }

  if (result.isLoading) {
    return <Loading />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className={commonFormStyles.edithFormHeader}>Support Request</div>
        <p className={commonFormStyles.requiredFields} />
        <Row>
          <Col className="col-12">
            <FormGroup>
              <Label
                for="userEmailAddress"
                className={commonFormStyles.requiredFieldLabel}
              >
                Your Email Address
              </Label>
              <input
                placeholder="someone@somewhere.com"
                type="text"
                autoComplete="email"
                {...register("userEmailAddress")}
                className={renderFormValidationClassNames(
                  errors.userEmailAddress,
                )}
              />
              <div className="invalid-feedback">
                {errors.userEmailAddress?.message}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <FormGroup>
              <Label
                for="userSupportRequest"
                className={commonFormStyles.requiredFieldLabel}
              >
                Support Request
              </Label>
              <textarea
                {...register("userSupportRequest")}
                rows={10}
                className={renderFormValidationClassNames(
                  errors.userSupportRequest,
                )}
              ></textarea>
              <div className="invalid-feedback">
                {errors.userSupportRequest?.message}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <button
              type="submit"
              className={`btn btn-primary ${commonFormStyles.formPullRight}`}
            >
              Submit Support Request
            </button>
          </Col>
        </Row>
      </div>
    </form>
  )
}
