import { Loading } from "../loading/Loading"
import { Navigate } from "react-router-dom"
import { useGetPrivacyPolicyQuery } from "../../api/EdithCmsApi"
import { MiscContentModel } from "../shared/MiscContentModel"

export function PrivacyPolicy() {
  const { data, isError, isLoading } = useGetPrivacyPolicyQuery({})
  const typedData = data as MiscContentModel

  if (isLoading) {
    return <Loading />
  } else if (isError) {
    return <Navigate to={"/program-error"} />
  }
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: typedData.CkEditorHtmlBodyPart.Html,
      }}
    />
  )
}
