import { SummaryModel } from "./SummaryModel"

export interface SummaryProperties {
  summary: SummaryModel
}

export function Summary(props: SummaryProperties) {
  return (
    <div
      className="modulePartContent"
      dangerouslySetInnerHTML={{
        __html: props.summary.ckEditorHtmlBody.html,
      }}
    ></div>
  )
}
