import { QuestionResponse } from "../responses/QuestionResponse"
import { MultipleChoiceActivityModel } from "./MultipleChoiceActivityModel"
import { FormGroup, Input, Label } from "reactstrap"
import { MultipleChoiceAnswer } from "../multiple-choice-activity-answer/MultipleChoiceAnswer"
import React from "react"

export interface MultipleChoiceActivityProperties {
  questionId: string
  multipleChoiceModel: MultipleChoiceActivityModel
  response: QuestionResponse | undefined
}

export function MultipleChoiceActivity(
  props: MultipleChoiceActivityProperties,
) {
  let orderedQuestions = props.multipleChoiceModel.answers.sort(
    (ad) => ad.order,
  )

  return (
    <div className="multipleChoice">
      <Input
        type="hidden"
        className="questionId"
        value={props.questionId}
        aria-hidden="true"
      />
      <FormGroup>
        <p>
          <b>Question:</b>
        </p>
        <Label>{props.multipleChoiceModel.question}</Label>
      </FormGroup>
      <FormGroup tag="fieldset">
        <Label>Select an answer:</Label>
        {orderedQuestions.map((ad, i) => {
          return (
            <MultipleChoiceAnswer
              key={i}
              multipleChoiceAnswerDetail={ad}
            ></MultipleChoiceAnswer>
          )
        })}
      </FormGroup>
    </div>
  )
}
