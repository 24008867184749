import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import counterReducer from "../features/counter/counterSlice"
import authenticationReducer from "../features/authentication/authenticationSlice"
import registrationReducer from "../features/registration/registrationSlice"
import moduleViewerReducer from "../features/module-viewer/ModuleViewerSlice"
import {
  AuthenticatedEdithCmsApi,
  AuthenticationApi,
  EdithCmsApi,
  GraphQlApi,
} from "../api/EdithCmsApi"
import { reducer as formReducer } from "redux-form"
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist/es/constants"

const persistConfig = {
  key: "applicationStore",
  storage,
}

const rootReducer = combineReducers({
  counter: counterReducer,
  authentication: authenticationReducer,
  registration: registrationReducer,
  moduleViewer: moduleViewerReducer,
  form: formReducer,
  [AuthenticatedEdithCmsApi.reducerPath]: AuthenticatedEdithCmsApi.reducer,
  [AuthenticationApi.reducerPath]: AuthenticationApi.reducer,
  [EdithCmsApi.reducerPath]: EdithCmsApi.reducer,
  [GraphQlApi.reducerPath]: GraphQlApi.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const rootMiddleware = [
  AuthenticatedEdithCmsApi.middleware,
  AuthenticationApi.middleware,
  EdithCmsApi.middleware,
  GraphQlApi.middleware,
]

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(rootMiddleware),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof persistedReducer>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
