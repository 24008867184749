import React from "react"
import { usePostPasswordResetRequestMutation } from "../../api/EdithCmsApi"
import { PasswordResetRequestModel } from "../../features/password-reset/PasswordResetRequestModel"
import { useForm } from "react-hook-form"
import { setNewPasswordValidation } from "./validation"
import { yupResolver } from "@hookform/resolvers/yup"
import { ProgramError } from "../../features/program-error/ProgramError"
import { Loading } from "../../features/loading/Loading"
import { SetNewPasswordModel } from "./SetNewPasswordModel"
import commonFormStyles from "../common/FormCommon.module.css"
import { Col, FormGroup, Label, Row } from "reactstrap"
import { renderFormValidationClassNames } from "../common/FormCommonFunctions"
import { Navigate } from "react-router-dom"
import { logout } from "../../features/authentication/authenticationSlice"
import { useAppDispatch } from "../../app/hooks"

export const SetNewPasswordForm = ({ requestId }: { requestId: string }) => {
  const [postPasswordReset, result] = usePostPasswordResetRequestMutation()
  const dispatch = useAppDispatch()

  const onSubmit = (model: SetNewPasswordModel) => {
    postPasswordReset({
      requestId: requestId,
      password: model.password,
    })
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(setNewPasswordValidation),
  })

  if (result.isError) {
    return <ProgramError error={result.error} />
  }

  if (result.isLoading) {
    return <Loading />
  }

  if (result.isSuccess) {
    dispatch(logout())
    return <Navigate to="/login" />
  }

  return (
    <form
      className={commonFormStyles.modalForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={commonFormStyles.edithFormHeader}>Set New Password</div>
      <p className={commonFormStyles.requiredFields} />
      <ul>
        <li>Passwords should be six(6) or more characters.</li>
        <li>Include one or more numbers or special characters.</li>
        <li>Include one or more upper case characters.</li>
        <li>Include one or more lower case characters.</li>
      </ul>
      <Row>
        <Col>
          <FormGroup>
            <Label
              for="password"
              className={commonFormStyles.requiredFieldLabel}
            >
              Password
            </Label>
            <input
              type="password"
              {...register("password")}
              className={renderFormValidationClassNames(errors.password)}
            />
            <div className="invalid-feedback">{errors.password?.message}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label
              for="confirmPassword"
              className={commonFormStyles.requiredFieldLabel}
            >
              Confirm Password
            </Label>
            <input
              type="password"
              {...register("confirmPassword")}
              className={renderFormValidationClassNames(errors.confirmPassword)}
            />
            <div className="invalid-feedback">
              {errors.confirmPassword?.message}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <button
            type="submit"
            className={`btn btn-primary ${commonFormStyles.formPullRight}`}
          >
            Update Password
          </button>
        </Col>
      </Row>
    </form>
  )
}
