import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AuthenticationTokenModel } from "./authenticationTokenModel"
import { RootState } from "../../app/store"
import { TokenEndpointErrorModel } from "./TokenEndpointErrorModel"

export interface AuthenticationState {
  status: "logged-out" | "logged-in"
  tokens: AuthenticationTokenModel
}

const initialState: AuthenticationState = {
  status: "logged-out",
  tokens: {
    access_token: "",
    token_type: "",
    expires_in: 0,
    scope: "",
    id_token: "",
    refresh_token: "",
  },
}

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logout: (state) => {
      state.status = "logged-out"
      state.tokens = initialState.tokens
    },
    updateTokens: (state, action: PayloadAction<AuthenticationTokenModel>) => {
      state.status = "logged-in"
      state.tokens = action.payload
    },
  },
})

export const { logout, updateTokens } = authenticationSlice.actions

export const selectSessionStatus = (state: RootState) =>
  state.authentication.status

export default authenticationSlice.reducer
