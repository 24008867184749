import { useGetPasswordResetRequestQuery } from "../../api/EdithCmsApi"
import { useParams } from "react-router"
import { Loading } from "../loading/Loading"
import { ProgramError } from "../program-error/ProgramError"
import { SetNewPasswordForm } from "../../forms/set-new-password/SetNewPasswordForm"
import commonFormStyles from "../../forms/common/FormCommon.module.css"
import styles from "./PasswordReset.module.css"

export function PasswordReset() {
  const { passwordResetRequestId } = useParams()
  if (passwordResetRequestId == undefined) {
    throw {
      message: "No reset id was provided.",
    }
  }
  const {
    data: any,
    isError,
    isLoading,
    error,
  } = useGetPasswordResetRequestQuery(passwordResetRequestId)

  if (isLoading) {
    return <Loading />
  } else if (isError) {
    // @ts-ignore
    if (error.originalStatus == 404) {
      return (
        <div className={commonFormStyles.modalForm}>
          <div className={`${commonFormStyles.edithFormHeader} ${styles.ohNo}`}>
            Oh No!
          </div>
          <div className={styles.ohNoMessage}>
            We're sorry that password reset request is either expired or
            consumed. If you still need to reset your password please restart
            the reset process from the login page.
          </div>
        </div>
      )
    } else {
      return <ProgramError error={error} />
    }
  }

  return <SetNewPasswordForm requestId={passwordResetRequestId} />
}
