import React, { MouseEventHandler } from "react"
import styles from "./OrganizationSelection.module.css"
import commonFormStyles from "../common/FormCommon.module.css"
import { Col, Row } from "reactstrap"
import { Ref, useForm } from "react-hook-form"

export interface OrganizationSelectionRef {
  HandleOrganizationSelection: (orgType: "individual" | "multi-user") => void
}

export const OrganizationSelectionForm = ({
  submitRef,
}: {
  submitRef: OrganizationSelectionRef
}) => {
  function handleIndividual() {
    submitRef.HandleOrganizationSelection("individual")
  }

  function handleMultiAccount() {
    submitRef.HandleOrganizationSelection("multi-user")
  }

  return (
    <form className={commonFormStyles.modalForm}>
      <Row>
        <Col>
          <div
            className={`${commonFormStyles.edithFormHeader} ${styles.organizationSelectionHeader}`}
          >
            Please select your organization type.
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <a
            className={"btn btn-primary " + styles.organizationSelectionButton}
            onClick={handleIndividual}
          >
            Individual
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <a
            className={"btn btn-primary " + styles.organizationSelectionButton}
            onClick={handleMultiAccount}
          >
            Organization
          </a>
        </Col>
      </Row>
    </form>
  )
}
