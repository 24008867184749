import { useParams } from "react-router"
import {
  useGetActivityResponsesQuery,
  useGetModuleDetailQuery,
  useGetModuleProgressQuery,
  usePostModuleCompletionMutation,
  usePostModuleProgressMutation,
} from "../../api/EdithCmsApi"
import { ProgramError } from "../program-error/ProgramError"
import { ModuleContentModel } from "../shared/ModuleContentModel"
import { ContentModuleContentItemBase } from "../shared/ContentModuleContentItemBase"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Progress,
  Row,
} from "reactstrap"
import { ModuleSummary } from "./module-parts/moduel-summary/ModuleSummary"
import styles from "./ModuleViewer.module.css"
import { Loading } from "../loading/Loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCamera,
  faComment,
  faCompressArrowsAlt,
  faNewspaper,
  faPoll,
  faPuzzlePiece,
  faVideo,
} from "@fortawesome/free-solid-svg-icons"
import { ActivityModel } from "./module-parts/activity/ActivityModel"
import { Activity } from "./module-parts/activity/Activity"
import { ArticleModel } from "./module-parts/article/ArticleModel"
import { Article } from "./module-parts/article/Article"
import { VisualModel } from "./module-parts/visual/VisualModel"
import { DefinitionModel } from "./module-parts/definition/DefinitionModel"
import { Definition } from "./module-parts/definition/Definition"
import { SummaryModel } from "./module-parts/summary/SummaryModel"
import { Summary } from "./module-parts/summary/Summary"
import { VideoModel } from "./module-parts/video/VideoModel"
import { Video } from "./module-parts/video/Video"
import { Visual } from "./module-parts/visual/Visual"
import { QualtricsSurveyModel } from "./module-parts/qualtrics-survey/QualtricsSurveyModel"
import { QualtricsSurvey } from "./module-parts/qualtrics-survey/QualtricsSurvey"
import { ActivityResponseRequestModel } from "./ActivityResponseRequestModel"
import { Navigate } from "react-router-dom"

export function ModuleViewer() {
  const { contentModuleItemId, contentModuleItemVersionId } = useParams()
  if (
    contentModuleItemId == undefined ||
    contentModuleItemVersionId == undefined
  ) {
    throw {
      message: "No content module item id provided.",
    }
  }
  const {
    data: moduleData,
    isError: moduleDataIsError,
    isLoading: moduleDataIsLoading,
    error: moduleDataError,
    isSuccess: moduleDataSuccess,
  } = useGetModuleDetailQuery({
    moduleId: contentModuleItemId,
  })

  const {
    data: progressData,
    isError: progressIsError,
    isLoading: progressIsLoading,
    error: progressError,
  } = useGetModuleProgressQuery({
    contentItemId: contentModuleItemId,
    contentItemVersionId: contentModuleItemVersionId,
  })

  let requestData = null
  if (moduleDataIsLoading) {
  } else {
    let activities = moduleData.data.activity as ActivityModel[]
    requestData = activities.map((act) => ({
      ActivityContentItemId: act.contentItemId,
      ActivityContentItemVersionId: act.contentItemVersionId,
    }))
  }

  const {
    data: responsesData,
    isError: responsesIsError,
    isLoading: responsesIsLoading,
    error: responsesError,
  } = useGetActivityResponsesQuery(
    {
      requests: requestData as ActivityResponseRequestModel[],
    },
    {
      skip: !moduleDataSuccess,
    },
  )
  const [postModuleProgress, resultProgress] = usePostModuleProgressMutation()
  const [postModuleCompletion, resultCompletion] =
    usePostModuleCompletionMutation()

  function flattenModuleData(content: ModuleContentModel) {
    let contentItems: ContentModuleContentItemBase[] = []

    contentItems = contentItems.concat(content.activity)
    contentItems = contentItems.concat(content.article)
    contentItems = contentItems.concat(content.definition)
    contentItems = contentItems.concat(content.summary)
    contentItems = contentItems.concat(content.video)
    contentItems = contentItems.concat(content.visual)
    contentItems = contentItems.concat(content.qualtricsSurvey)

    if (contentItems.length > 1) {
      contentItems = contentItems.sort((a, b) => {
        if (
          a.contentModuleSequence.sequence > b.contentModuleSequence.sequence
        ) {
          return 1
        }

        return -1
      })
    }

    return contentItems
  }

  function handleCardTitleClick(index: number) {
    let moduleContentItemId = moduleData.data.module[0].contentItemId
    let moduleContentItemVersionId =
      moduleData.data.module[0].contentItemVersionId

    postModuleProgress({
      contentItemId: moduleContentItemId,
      contentItemVersionId: moduleContentItemVersionId,
      progress: index,
    })
  }

  function handleCompletion(
    moduleContentItemId: string,
    moduleContentItemVersionId: string,
  ) {
    postModuleCompletion({
      moduleContentItemId,
      moduleContentItemVersionId,
      completed: true,
    })
  }

  function handleCardKeyDown(
    event: React.KeyboardEvent<HTMLElement>,
    index: number,
  ) {
    if (event.key === " " || event.key === "Enter") {
      let moduleContentItemId = moduleData.data.module[0].contentItemId
      let moduleContentItemVersionId =
        moduleData.data.module[0].contentItemVersionId

      postModuleProgress({
        contentItemId: moduleContentItemId,
        contentItemVersionId: moduleContentItemVersionId,
        progress: index,
      })
    }
  }

  function renderDisabled(currentProgress: number, flatDataLength: number) {
    return currentProgress == flatDataLength ? "" : "disabled"
  }

  if (moduleDataIsLoading || progressIsLoading || responsesIsLoading) {
    return <Loading />
  }

  if (moduleDataIsError) {
    return <ProgramError error={moduleDataError} />
  }

  if (progressIsError) {
    return <ProgramError error={progressError} />
  }

  if (responsesIsError) {
    return <ProgramError error={responsesError} />
  }

  if (resultCompletion.isSuccess) {
    return <Navigate to="/module-selection" />
  }

  const flatData = flattenModuleData(moduleData.data)
  let tabIndex = 0
  let currentProgress = progressData.currentProgress

  return (
    <>
      <Row>
        <Col>
          <ModuleSummary moduleDetail={moduleData.data.module[0]} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Progress
            className={styles.moduleProgress}
            value={((progressData.currentProgress + 1) / flatData.length) * 100}
            hidden={!(flatData.length > 0)}
          />
        </Col>
      </Row>
      {flatData.map((modulePart, i) => {
        switch (modulePart.contentType) {
          case "Activity":
            let activity = modulePart as ActivityModel
            tabIndex += 1
            return (
              <Card
                key={i}
                className={styles.activityCard}
                tabIndex={0}
                onKeyDown={(event) => handleCardKeyDown(event, i)}
              >
                <CardBody
                  role="button"
                  onClick={() => handleCardTitleClick(i)}
                  className={currentProgress == i ? styles.activeCard : ""}
                >
                  <CardTitle>
                    <h3>
                      <FontAwesomeIcon
                        className={styles.activityIcon}
                        icon={faPuzzlePiece}
                      />
                      {activity.displayText}
                    </h3>
                  </CardTitle>
                </CardBody>
                <Collapse isOpen={currentProgress == i}>
                  <CardBody>
                    <Activity
                      key={i}
                      activity={activity}
                      responses={responsesData}
                    />
                  </CardBody>
                </Collapse>
              </Card>
            )
          case "Article":
            let article = modulePart as ArticleModel
            tabIndex += 1
            return (
              <Card
                key={i}
                className={styles.activityCard}
                tabIndex={0}
                onKeyDown={(event) => handleCardKeyDown(event, i)}
              >
                <CardBody
                  role="button"
                  onClick={() => handleCardTitleClick(i)}
                  className={currentProgress == i ? styles.activeCard : ""}
                >
                  <CardTitle>
                    <h3>
                      <FontAwesomeIcon
                        className={styles.activityIcon}
                        icon={faNewspaper}
                      />
                      {article.displayText}
                    </h3>
                  </CardTitle>
                </CardBody>
                <Collapse isOpen={currentProgress == i}>
                  <CardBody>
                    <Article key={i} article={article} />
                  </CardBody>
                </Collapse>
              </Card>
            )
          case "Definition":
            let definition = modulePart as DefinitionModel
            tabIndex += 1
            return (
              <Card
                key={i}
                className={styles.activityCard}
                tabIndex={0}
                onKeyDown={(event) => handleCardKeyDown(event, i)}
              >
                <CardBody
                  role="button"
                  onClick={() => handleCardTitleClick(i)}
                  className={currentProgress == i ? styles.activeCard : ""}
                >
                  <CardTitle>
                    <h3>
                      <FontAwesomeIcon
                        className={styles.activityIcon}
                        icon={faComment}
                      />
                      {definition.displayText}
                    </h3>
                  </CardTitle>
                </CardBody>
                <Collapse isOpen={currentProgress == i}>
                  <CardBody>
                    <Definition key={i} definition={definition} />
                  </CardBody>
                </Collapse>
              </Card>
            )
          case "Summary":
            let summary = modulePart as SummaryModel
            tabIndex += 1
            return (
              <Card
                key={i}
                className={styles.activityCard}
                tabIndex={0}
                onKeyDown={(event) => handleCardKeyDown(event, i)}
              >
                <CardBody
                  role="button"
                  onClick={() => handleCardTitleClick(i)}
                  className={currentProgress == i ? styles.activeCard : ""}
                >
                  <CardTitle>
                    <h3>
                      <FontAwesomeIcon
                        className={styles.activityIcon}
                        icon={faCompressArrowsAlt}
                      />
                      {summary.displayText}
                    </h3>
                  </CardTitle>
                </CardBody>
                <Collapse isOpen={currentProgress == i}>
                  <CardBody>
                    <Summary key={i} summary={summary} />
                  </CardBody>
                </Collapse>
              </Card>
            )
          case "Video":
            let video = modulePart as VideoModel
            tabIndex += 1
            return (
              <Card
                key={i}
                className={styles.activityCard}
                tabIndex={0}
                onKeyDown={(event) => handleCardKeyDown(event, i)}
              >
                <CardBody
                  role="button"
                  onClick={() => handleCardTitleClick(i)}
                  className={currentProgress == i ? styles.activeCard : ""}
                >
                  <CardTitle>
                    <h3>
                      <FontAwesomeIcon
                        className={styles.activityIcon}
                        icon={faVideo}
                      />
                      {video.displayText}
                    </h3>
                  </CardTitle>
                </CardBody>
                <Collapse isOpen={currentProgress == i}>
                  <CardBody>
                    <Video key={i} video={video} />
                  </CardBody>
                </Collapse>
              </Card>
            )
          case "Visual":
            let visual = modulePart as VisualModel
            tabIndex += 1
            return (
              <Card
                key={i}
                className={styles.activityCard}
                tabIndex={0}
                onKeyDown={(event) => handleCardKeyDown(event, i)}
              >
                <CardBody
                  role="button"
                  onClick={() => handleCardTitleClick(i)}
                  className={currentProgress == i ? styles.activeCard : ""}
                >
                  <CardTitle>
                    <h3>
                      <FontAwesomeIcon
                        className={styles.activityIcon}
                        icon={faCamera}
                      />
                      {visual.displayText}
                    </h3>
                  </CardTitle>
                </CardBody>
                <Collapse isOpen={currentProgress == i}>
                  <CardBody>
                    <Visual key={i} visual={visual} />
                  </CardBody>
                </Collapse>
              </Card>
            )
          case "QualtricsSurvey":
            let survey = modulePart as QualtricsSurveyModel
            tabIndex += 1
            return (
              <Card
                key={i}
                className={styles.activityCard}
                tabIndex={0}
                onKeyDown={(event) => handleCardKeyDown(event, i)}
              >
                <CardBody
                  role="button"
                  onClick={() => handleCardTitleClick(i)}
                  className={currentProgress == i ? styles.activeCard : ""}
                >
                  <CardTitle>
                    <h3>
                      <FontAwesomeIcon
                        className={styles.activityIcon}
                        icon={faPoll}
                      />
                      {survey.displayText}
                    </h3>
                  </CardTitle>
                </CardBody>
                <Collapse isOpen={currentProgress == i}>
                  <CardBody>
                    <QualtricsSurvey key={i} qualtricsSurvey={survey} />
                  </CardBody>
                </Collapse>
              </Card>
            )
        }
      })}
      <Button
        color="success"
        className={styles.completeModuleButton}
        onClick={() =>
          handleCompletion(contentModuleItemId, contentModuleItemVersionId)
        }
      >
        Complete Module
      </Button>
    </>
  )
}
