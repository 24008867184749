import { MultipleChoiceAnswerModel } from "./MultipleChoiceAnswerModel"
import { FormGroup, Input, Label } from "reactstrap"

export interface MultipleChoiceAnswerProperties {
  multipleChoiceAnswerDetail: MultipleChoiceAnswerModel
}

export function MultipleChoiceAnswer(props: MultipleChoiceAnswerProperties) {
  function getOrder(detail: MultipleChoiceAnswerModel) {
    if (detail == undefined) {
      return "-1"
    }

    return detail.order.toString()
  }

  return (
    <FormGroup check>
      <Label check>
        <Input type="radio" name={getOrder(props.multipleChoiceAnswerDetail)} />{" "}
        {props.multipleChoiceAnswerDetail.answer}
      </Label>
    </FormGroup>
  )
}
