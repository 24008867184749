import {
  useGetAccountMetaQuery,
  useGetModuleCompletionsQuery,
  useGetModulesListingQuery,
} from "../../api/EdithCmsApi"
import { ModuleListing } from "./ModuleListing"
import { Loading } from "../loading/Loading"
import { ProgramError } from "../program-error/ProgramError"
import { GraphQLResponseBase } from "../shared/GraphQLResponseBase"
import { ModuleCompletionModel } from "../shared/ModuleCompletionModel"
import styles from "./ModuleSelection.module.css"
import { Link } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { AccountMeta } from "../shared/AccountMeta"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { MediaModel } from "../handouts/MediaModel"

export function ModuleSelection() {
  const {
    data: moduleData,
    isError: moduleListingIsError,
    isLoading: moduleListingLoading,
    error: moduleListingError,
  } = useGetModulesListingQuery({})

  const {
    data: completionData,
    isError: completionsIsError,
    isLoading: completionsLoading,
    error: completionsError,
  } = useGetModuleCompletionsQuery({})

  const {
    data: accountMetaData,
    isError: accountMetaIsError,
    isLoading: accountMetaIsLoading,
    error: accountMetaError,
  } = useGetAccountMetaQuery({})

  if (moduleListingLoading || completionsLoading || accountMetaIsLoading) {
    return <Loading />
  } else if (moduleListingIsError) {
    return <ProgramError error={moduleListingError} />
  } else if (completionsIsError) {
    return <ProgramError error={completionsError} />
  } else if (accountMetaIsError) {
    return <ProgramError error={accountMetaError} />
  }

  const listing = moduleData as GraphQLResponseBase<ModuleListing>
  const orderedModules = listing.data.module.slice().sort((a, b) => {
    if (a.contentModuleSequence.sequence > b.contentModuleSequence.sequence) {
      return 1
    } else {
      return -1
    }
  })
  const completions = completionData as ModuleCompletionModel[]

  function renderCompleted(completed?: boolean) {
    if (completed == null) {
      return
    }

    if (completed) {
      return <small>Completed</small>
    } else {
      return <small>Incomplete</small>
    }
  }

  function isModuleComplete(
    completions: ModuleCompletionModel[],
    contentItemId: string,
    contentItemVersionId: string,
  ) {
    if (!completions) {
      return false
    }

    let completion = completions.find(
      (item) =>
        item.moduleContentItemId == contentItemId &&
        item.moduleContentItemVersionId == contentItemVersionId,
    )

    if (completion) {
      return completion.completed
    }

    return false
  }

  const accountMeta = accountMetaData as AccountMeta
  const displayPaidModules = accountMeta.isPaidAccount

  const filteredModules = orderedModules.filter((value, index) => {
    if (value.paidContent != null) {
      if (value.paidContent) {
        return displayPaidModules
      }
    }
    return true
  })

  function RenderUpgradeNotice() {
    if (!accountMeta.isPaidAccount) {
      return (
        <>
          <Row>
            <p className={styles.upgradePlea}>
              Unlock the full potential of your journey with us! By upgrading to
              full access, you'll delve deeper into our comprehensive
              curriculum, gaining a richer understanding of critical topics like
              racial justice, diversity, and equity. Don't miss out on the
              opportunity to explore additional modules, engage in
              transformative discussions, and truly immerse yourself in the
              quest for meaningful change. Upgrade now and be a catalyst for a
              more just and equitable world.
            </p>
          </Row>
          <Row>
            <Col>
              <a href="/upgrade" className={styles.upgradeAnchor}>
                <div className={styles.upgradeButton}>
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className={styles.upgradeButtonChevron}
                  />
                  Get Full Access
                </div>
              </a>
            </Col>
          </Row>
        </>
      )
    }
  }

  function RenderCoverImage(imageData: MediaModel) {
    if (imageData == null || imageData.urls.length == 0) {
      return
    }

    const thumbnailUri = `${import.meta.env.VITE_EDITH_CMS_ROOT_URI}${
      imageData.urls[0]
    }`

    return (
      <div className="row">
        <img className={styles.coverImage} src={thumbnailUri} />
      </div>
    )
  }

  return (
    <>
      <Row>
        {filteredModules.map(function (item, i) {
          return (
            <Link
              className={`col-xs-12 col-sm-6 col-md-4 col-lg-3 ${styles.moduleLink}`}
              to={`/module-viewer/${item.contentItemId}/${item.contentItemVersionId}`}
              key={item.contentItemId}
            >
              <div className={styles.moduleButton}>
                <div className={"row " + styles.coverTitle}>
                  <span>{item.contentModule.moduleName}</span>
                </div>
                {RenderCoverImage(item.coverImage)}
                <div className={styles.moduleButtonBottom}>
                  <hr className={styles.buttonHr} aria-hidden={true} />
                  {renderCompleted(
                    isModuleComplete(
                      completions,
                      item.contentItemId,
                      item.contentItemVersionId,
                    ),
                  )}
                </div>
              </div>
            </Link>
          )
        })}
      </Row>
      {RenderUpgradeNotice()}
    </>
  )
}
