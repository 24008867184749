import React, { useEffect } from "react"
import { useGetAccessTokenMutation } from "../../api/EdithCmsApi"
import { useAppDispatch } from "../../app/hooks"
import { LoginModel } from "./LoginModel"
import { Loading } from "../../features/loading/Loading"
import { updateTokens } from "../../features/authentication/authenticationSlice"
import { Navigate } from "react-router-dom"
import { RTKQueryError } from "../../features/shared/RTKQueryError"
import { OpenIdError } from "../../features/shared/OpenIdError"
import { Alert, Col, FormGroup, Label, Row } from "reactstrap"
import styles from "./Login.module.css"
import commonFormStyles from "../common/FormCommon.module.css"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { loginValidation } from "./validation"
import { renderFormValidationClassNames } from "../common/FormCommonFunctions"

export const LoginForm = () => {
  const dispatch = useAppDispatch()
  const [login, loginResult] = useGetAccessTokenMutation()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidation),
  })

  const onSubmit = (loginModel: LoginModel) => {
    login({ username: loginModel.username, password: loginModel.password })
  }

  useEffect(() => {
    if (loginResult.isSuccess) {
      dispatch(updateTokens(loginResult.data))
    }
  })

  if (loginResult.isLoading) {
    return <Loading />
  }

  if (loginResult.isSuccess) {
    return <Navigate to="/module-selection"></Navigate>
  }

  function renderError() {
    if (!loginResult.isError) {
      return
    }

    let rtkError = loginResult.error as RTKQueryError
    let openIdError = rtkError.data as OpenIdError

    return renderAlert(openIdError.error_description)
  }

  function renderAlert(message: string) {
    return (
      <Alert
        role="alert"
        aria-live="assertive"
        color="danger"
        className="message"
      >
        {message}
      </Alert>
    )
  }

  return (
    <>
      {renderError()}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={commonFormStyles.modalForm}
      >
        <div className={commonFormStyles.edithFormHeader}>Please Login</div>
        <p className={commonFormStyles.requiredFields} />
        <div className={commonFormStyles.edithFormBody}>
          <Row>
            <Col>
              <Label
                for="username"
                className={commonFormStyles.requiredFieldLabel}
              >
                Username
              </Label>
              <input
                placeholder="Your Username"
                type="text"
                autoComplete="username"
                {...register("username")}
                className={renderFormValidationClassNames(errors.username)}
              />
              <div className="invalid-feedback">{errors.username?.message}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  for="password"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Password
                </Label>
                <input
                  placeholder="Your Password"
                  type="password"
                  autoComplete="new-password"
                  {...register("password")}
                  className={renderFormValidationClassNames(errors.password)}
                />
                <div className="invalid-feedback">
                  {errors.password?.message}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className={styles.loginLinkCol}>
              <a className={styles.loginLink} href="/registration">
                Register New Account
              </a>
            </Col>
            <Col className={styles.loginLinkCol}>
              <a className={styles.loginLink} href="/reset-password-reset">
                Forgotten Password?
              </a>
            </Col>
            <Col className="col-2">
              <button
                type="submit"
                className={`btn btn-primary ${styles.loginButtonSubmit} ${commonFormStyles.formPullRight}`}
              >
                Login
              </button>
            </Col>
          </Row>
        </div>
      </form>
    </>
  )
}
