import { BasicProfileModel } from "./BasicProfileModel"
import { Col, FormGroup, Label, Row } from "reactstrap"
import states from "../common/util/states_titlecase.json"
import React from "react"
import { basicProfileValidation } from "./validation"
import { usePostBasicProfileMutation } from "../../api/EdithCmsApi"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { renderFormValidationClassNames } from "../common/FormCommonFunctions"
import { ProgramError } from "../../features/program-error/ProgramError"
import commonFormStyles from "../common/FormCommon.module.css"

export const BasicProfileForm = ({
  defaultData,
}: {
  defaultData: BasicProfileModel
}) => {
  const [postBasicProfile, result] = usePostBasicProfileMutation()

  const onSubmit = (data: BasicProfileModel) => {
    postBasicProfile(data)
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultData,
    resolver: yupResolver(basicProfileValidation),
  })
  if (result.isError) {
    return <ProgramError error={result.error} />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="edith-form-body">
        <Col className="col-6">
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <input
              type="text"
              autoComplete="given-name"
              {...register("firstName")}
              className={renderFormValidationClassNames(errors.firstName)}
            />
            <div className="invalid-feedback">{errors.firstName?.message}</div>
          </FormGroup>
        </Col>
        <Col className="col-6">
          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <input
              type="text"
              autoComplete="family-name"
              {...register("lastName")}
              className={renderFormValidationClassNames(errors.lastName)}
            />
            <div className="invalid-feedback">{errors.lastName?.message}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <button
            type="submit"
            className={`btn btn-primary ${commonFormStyles.formPullRight}`}
          >
            Update
          </button>
        </Col>
      </Row>
    </form>
  )
}
