import { DefinitionModel } from "./DefinitionModel"

export interface DefinitionProperties {
  definition: DefinitionModel
}

export function Definition(props: DefinitionProperties) {
  return (
    <div
      className="modulePartContent"
      dangerouslySetInnerHTML={{
        __html: props.definition.ckEditorHtmlBody.html,
      }}
    ></div>
  )
}
