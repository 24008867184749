import {
  useGetAccountMetaQuery,
  useGetHandoutListingQuery,
} from "../../api/EdithCmsApi"
import { Loading } from "../loading/Loading"
import { ProgramError } from "../program-error/ProgramError"
import { HandoutModel } from "./HandoutModel"
import { AccountMeta } from "../shared/AccountMeta"
import styles from "./Handouts.module.css"
import { Row } from "reactstrap"
import { Link } from "react-router-dom"

export function Handouts() {
  const {
    data: handoutData,
    isError: handoutIsError,
    isLoading: handoutIsLoading,
    error: handoutError,
  } = useGetHandoutListingQuery({})

  const {
    data: accountMetaData,
    isError: accountMetaIsError,
    isLoading: accountMetaIsLoading,
    error: accountMetaError,
  } = useGetAccountMetaQuery({})

  if (handoutIsLoading || accountMetaIsLoading) {
    return <Loading />
  } else if (handoutIsError) {
    return <ProgramError error={handoutError} />
  } else if (accountMetaIsError) {
    return <ProgramError error={accountMetaError} />
  }

  const accountMeta = accountMetaData as AccountMeta

  function filterHandouts(
    element: HandoutModel,
    index: number,
    array: HandoutModel[],
  ) {
    if (!element.published) {
      return false
    }

    if (!element.paidContent) {
      return true
    }

    if (accountMeta.isPaidAccount) {
      return true
    }

    return false
  }

  const listing = handoutData.data.handout as HandoutModel[]
  const filteredListing = listing.filter(filterHandouts)

  if (filteredListing.length == 0) {
    var message = `Sorry, there are no additional resources available at this time.`
    if (!accountMeta.isPaidAccount) {
      message += ` Since you are on a free account there may be additional resources that are not available to you at this time.`
    }

    return <h2 className={styles.noHandoutsMessage}>{message}</h2>
  }

  return (
    <>
      <h2>Resources</h2>
      <Row>
        {filteredListing.map(function (item, i) {
          const thumbnailUri = `${import.meta.env.VITE_EDITH_CMS_ROOT_URI}${
            item.thumbnail.urls[0]
          }`
          const downloadUri = `${import.meta.env.VITE_EDITH_CMS_ROOT_URI}${
            item.downloadUrl.urls[0]
          }`
          return (
            <Link
              className={`col-xs-12 col-sm-6 col-md-4 col-lg-3 ${styles.handoutLink}`}
              to={downloadUri}
              download
              target="_blank"
              key={item.contentItemId}
            >
              <div className={`p-2 ${styles.handoutButton}`}>
                <img className={styles.handoutThumbnail} src={thumbnailUri} />
                <div className={styles.handoutButtonBottom}>
                  {item.displayText}
                </div>
              </div>
            </Link>
          )
        })}
      </Row>
    </>
  )
}
