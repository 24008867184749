import { Loading } from "../loading/Loading"
import { useGetHomePageQuery } from "../../api/EdithCmsApi"
import { ProgramError } from "../program-error/ProgramError"
import { MiscContentModel } from "../shared/MiscContentModel"

export function HomePage() {
  const { data, isError, isLoading, error } = useGetHomePageQuery({})
  const typedData = data as MiscContentModel

  if (isLoading) {
    return <Loading />
  } else if (isError) {
    return <ProgramError error={error} />
  }
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: typedData.CkEditorHtmlBodyPart.Html,
      }}
    />
  )
}
