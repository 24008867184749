import { VisualModel } from "./VisualModel"

export interface VisualProperties {
  visual: VisualModel
}

export function Visual(props: VisualProperties) {
  return (
    <div
      className="modulePartContent"
      dangerouslySetInnerHTML={{
        __html: props.visual.ckEditorHtmlBody.html,
      }}
    ></div>
  )
}
