import { ExternalActivityModel } from "./ExternalActivityModel"

export interface ExternalActivityProperties {
  externalActivity: ExternalActivityModel
}

export function ExternalActivity(props: ExternalActivityProperties) {
  return (
    <p>
      This activity is on an external site. Click here to proceed:
      <a href={props.externalActivity.uri}>External Activity</a>
    </p>
  )
}
