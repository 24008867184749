import {
  useGetBasicProfileQuery,
  useGetProfileAddressesQuery,
  useGetProfilePhoneNumberQuery,
} from "../../api/EdithCmsApi"
import { Loading } from "../loading/Loading"
import { ProgramError } from "../program-error/ProgramError"
import { BasicProfileForm } from "../../forms/basic-profile/BasicProfileForm"
import { ProfileAddressesForm } from "../../forms/profile-addresses/ProfileAddressesForm"
import commonFormStyles from "../../forms/common/FormCommon.module.css"
import { ProfilePhoneNumberForm } from "../../forms/profile-phone-number/ProfilePhoneNumberForm"

export function Profile() {
  const {
    data: basicProfileData,
    isError,
    isLoading,
    error,
  } = useGetBasicProfileQuery({})
  const {
    data: profileAddressData,
    isError: isErrorAddresses,
    isLoading: isLoadingAddresses,
    error: errorAddresses,
  } = useGetProfileAddressesQuery({})
  const {
    data: profilePhoneData,
    isError: isErrorPhoneData,
    isLoading: isLoadingPhoneNumbers,
    error: errorPhoneNumbers,
  } = useGetProfilePhoneNumberQuery({})

  if (isLoading || isLoadingAddresses || isLoadingPhoneNumbers) {
    return <Loading />
  } else if (isError) {
    return <ProgramError error={error} />
  } else if (isErrorAddresses) {
    return <ProgramError error={errorAddresses} />
  } else if (isErrorPhoneData) {
    return <ProgramError error={errorPhoneNumbers} />
  }

  return (
    <>
      <fieldset className={commonFormStyles.formFieldSet}>
        <legend>Basic Profile</legend>
        <BasicProfileForm defaultData={basicProfileData} />
      </fieldset>
      <fieldset className={commonFormStyles.formFieldSet}>
        <legend>Address Information</legend>
        <ProfileAddressesForm defaultData={profileAddressData} />
      </fieldset>
      <fieldset className={commonFormStyles.formFieldSet}>
        <legend>Phone Information</legend>
        <ProfilePhoneNumberForm defaultData={profilePhoneData} />
      </fieldset>
    </>
  )
}
