import { ActivityResponse } from "./activity-parts/responses/ActivityResponse"
import { ModuleContentModel } from "../shared/ModuleContentModel"
import { GraphQLEndpointErrorModel } from "../shared/graph-ql/GraphQLEndpointErrorModel"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"


export interface ModuleViewerState {
  status: "idle" | "loading" | "saving" | "failed"
  moduleData: ModuleContentModel
  hasError: boolean
  errorDetail: GraphQLEndpointErrorModel
  currentProgress: number
  loading: string[]
  saving: string[]
  activityResponses: ActivityResponse[]
}

const initialState: ModuleViewerState = {
  status: "idle",
  moduleData: {
    activity: [],
    article: [],
    definition: [],
    module: [],
    summary: [],
    video: [],
    visual: [],
    qualtricsSurvey: [],
  },
  hasError: false,
  currentProgress: 0,
  errorDetail: {
    errors: [],
  },
  loading: [],
  saving: [],
  activityResponses: [],
}

export const selectResponses = (state: RootState) =>
  state.moduleViewer.activityResponses

export const moduleViewerSlice = createSlice({
  name: "moduleViewer",
  initialState,
  reducers: {},
})

export default moduleViewerSlice.reducer
