import { ModuleDetailModel } from "../../ModuleDetailModel"

export interface ModuleSummaryProps {
  moduleDetail: ModuleDetailModel
}

export function ModuleSummary(props: ModuleSummaryProps) {
  return (
    <div>
      <h1 className="moduleTitle">
        {props.moduleDetail.contentModule.moduleName}
      </h1>
      <p
        dangerouslySetInnerHTML={{
          __html: props.moduleDetail.ckEditorHtmlBody.html,
        }}
      ></p>
    </div>
  )
}
