import * as Yup from "yup"
import { ref } from "yup"

export const NoPasswordMessage = "Please provide a new password."
export const PasswordNotLongEnough =
  "Please make a password eight(8) characters or longer."
export const PasswordNoSpecialCharacters =
  "Please make a password that includes one or more numbers or special characters."
export const PasswordNoLower =
  "Please make a password with at least one(1) lower case characters."
export const PasswordNoUpper =
  "Please make a password with at least one(1) upper case characters."
export const NoConfirmationPassword = "Please confirm your password."
export const ConfirmationNotMatching = "Please make sure your passwords match."

export const setNewPasswordValidation = Yup.object().shape({
  password: Yup.string()
    .required(NoPasswordMessage)
    .min(8, PasswordNotLongEnough)
    .matches(
      /((?=.[1234567890-=!@#$%^&*()_+`~[\]{};:"\|,.\/<>?]){1})/,
      PasswordNoSpecialCharacters,
    )
    .matches(/[A-Z]/, PasswordNoUpper)
    .matches(/[a-z]/, PasswordNoLower),
  confirmPassword: Yup.string()
    .required(NoConfirmationPassword)
    .oneOf([ref("password")], ConfirmationNotMatching),
})
