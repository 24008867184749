import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./ProgramError.module.css"
import { v4 as uuidv4 } from "uuid"
import { faBomb } from "@fortawesome/free-solid-svg-icons"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Row,
  UncontrolledAccordion,
} from "reactstrap"
import { useState } from "react"
import { Navigate } from "react-router-dom"

export interface ProgramErrorProperties {
  error: unknown
}

export function ProgramError(props: ProgramErrorProperties) {
  const [nerdStuffOpen, setNerdStuffOpen] = useState("0")
  let id = uuidv4()

  // @ts-ignore
  if (props.error?.status == 401) {
    return <Navigate to="/login" />
  }

  return (
    <Row>
      <div className={styles.bombRainbow}>
        <FontAwesomeIcon icon={faBomb} className={styles.bombOne} />
        <FontAwesomeIcon icon={faBomb} className={styles.bombTwo} />
        <FontAwesomeIcon icon={faBomb} className={styles.bombThree} />
        <FontAwesomeIcon icon={faBomb} className={styles.bombFour} />
        <FontAwesomeIcon icon={faBomb} className={styles.bombFive} />
        <FontAwesomeIcon icon={faBomb} className={styles.bombSix} />
        <FontAwesomeIcon icon={faBomb} className={styles.bombSeven} />
        <FontAwesomeIcon icon={faBomb} className={styles.bombEight} />
        <FontAwesomeIcon icon={faBomb} className={styles.bombNine} />
        <FontAwesomeIcon icon={faBomb} className={styles.bombTen} />
        <FontAwesomeIcon icon={faBomb} className={styles.bombEleven} />
      </div>
      <div className={styles.programErrorTextContainer}>
        <p className={styles.ohNoText}>Oh No!</p>
        <p className={styles.errorText}>
          We're so sorry, there was a program error.
        </p>
        <p>
          Your error was logged and we are working on it. You can try again but
          if you continue to experience an issue please feel free to reach out.
        </p>
      </div>
      <div>
        <UncontrolledAccordion
          className={styles.stuffForNerds}
          defaultOpen={"0"}
        >
          <AccordionItem>
            <AccordionHeader targetId={"1"}>Stuff for nerds</AccordionHeader>
            <AccordionBody accordionId="1">
              <p>Error Id: {id}</p>
              <p>Error Payload</p>
              <pre className={styles.codeBlock}>
                {JSON.stringify(props.error, null, 4)}
              </pre>
            </AccordionBody>
          </AccordionItem>
        </UncontrolledAccordion>
      </div>
    </Row>
  )
}
