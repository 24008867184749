import { NavMenu } from "../nav-menu/NavMenu"
import { Container } from "reactstrap"
import { ReactNode } from "react"
import { Footer } from "../footer/Footer"

export const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <NavMenu />
      <Container>
        <main>{children}</main>
      </Container>
      <Footer />
    </>
  )
}
