import { Col, FormGroup, Label, Row } from "reactstrap"
import states from "../common/util/states_titlecase.json"
import React from "react"
import { usePostProfileAddressesMutation } from "../../api/EdithCmsApi"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { renderFormValidationClassNames } from "../common/FormCommonFunctions"
import { ProgramError } from "../../features/program-error/ProgramError"
import { profileAddressValidation } from "./validation"
import { ProfileAddressModel } from "./ProfileAddressModel"
import commonFormStyles from "../common/FormCommon.module.css"

export const ProfileAddressesForm = ({
  defaultData,
}: {
  defaultData: ProfileAddressModel
}) => {
  const [postProfileAddresses, result] = usePostProfileAddressesMutation()

  const onSubmit = (data: ProfileAddressModel) => {
    postProfileAddresses(data)
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultData,
    resolver: yupResolver(profileAddressValidation),
  })
  if (result.isError) {
    return <ProgramError error={result.error} />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        {/* Mailing Address */}
        <Col>
          <FormGroup>
            <Label for="mailingAddress.address1">Address 1</Label>
            <input
              type="text"
              {...register("mailingAddress.address1")}
              className={renderFormValidationClassNames(
                errors.mailingAddress?.address1,
              )}
            />
            <div className="invalid-feedback">
              {errors.mailingAddress?.address1?.message}
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="mailingAddress.address2">Address 2</Label>
            <input
              type="text"
              {...register("mailingAddress.address2")}
              className={renderFormValidationClassNames(
                errors.mailingAddress?.address2,
              )}
            />
            <div className="invalid-feedback">
              {errors.mailingAddress?.address2?.message}
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="mailingAddress.address2">City</Label>
            <input
              type="text"
              {...register("mailingAddress.city")}
              className={renderFormValidationClassNames(
                errors.mailingAddress?.city,
              )}
            />
            <div className="invalid-feedback">
              {errors.mailingAddress?.city?.message}
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="mailingAddress.zipCode">Zip Code</Label>
            <input
              type="text"
              {...register("mailingAddress.zipCode")}
              className={renderFormValidationClassNames(
                errors.mailingAddress?.zipCode,
              )}
            />
            <div className="invalid-feedback">
              {errors.mailingAddress?.zipCode?.message}
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="state">State</Label>
            <div className="inputWrapper">
              <select
                placeholder="Select Your State"
                autoComplete="address-level1"
                {...register("mailingAddress.state")}
                className={renderFormValidationClassNames(
                  errors.mailingAddress?.state,
                  ["form-select", "state-select"],
                )}
              >
                <option value="">Select a State</option>
                {states.map(function (item, i) {
                  return (
                    <option key={i} value={item.abbreviation}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
              <div className="invalid-feedback">
                {errors.mailingAddress?.state?.message}
              </div>
            </div>
          </FormGroup>
        </Col>
        {/* Billing Address */}
        <Col>
          <FormGroup>
            <Label for="billingAddress.address1">Address 1</Label>
            <input
              type="text"
              {...register("billingAddress.address1")}
              className={renderFormValidationClassNames(
                errors.billingAddress?.address1,
              )}
              disabled={watch("mailingAndBillingIdentical")}
            />
            <div className="invalid-feedback">
              {errors.billingAddress?.address1?.message}
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="billingAddress.address2">Address 2</Label>
            <input
              type="text"
              {...register("billingAddress.address2")}
              className={renderFormValidationClassNames(
                errors.billingAddress?.address2,
              )}
              disabled={watch("mailingAndBillingIdentical")}
            />
            <div className="invalid-feedback">
              {errors.billingAddress?.address2?.message}
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="billingAddress.address2">City</Label>
            <input
              type="text"
              {...register("billingAddress.city")}
              className={renderFormValidationClassNames(
                errors.billingAddress?.city,
              )}
              disabled={watch("mailingAndBillingIdentical")}
            />
            <div className="invalid-feedback">
              {errors.billingAddress?.city?.message}
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="billingAddress.zipCode">Zip Code</Label>
            <input
              type="text"
              {...register("billingAddress.zipCode")}
              className={renderFormValidationClassNames(
                errors.billingAddress?.zipCode,
              )}
              disabled={watch("mailingAndBillingIdentical")}
            />
            <div className="invalid-feedback">
              {errors.billingAddress?.zipCode?.message}
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="state">State</Label>
            <div className="inputWrapper">
              <select
                placeholder="Select Your State"
                autoComplete="address-level1"
                {...register("billingAddress.state")}
                className={renderFormValidationClassNames(
                  errors.billingAddress?.state,
                  ["form-select", "state-select"],
                )}
                disabled={watch("mailingAndBillingIdentical")}
              >
                <option value="">Select a State</option>
                {states.map(function (item, i) {
                  return (
                    <option key={i} value={item.abbreviation}>
                      {item.name}
                    </option>
                  )
                })}
                disabled={watch("mailingAndBillingIdentical")}
              </select>
              <div className="invalid-feedback">
                {errors.billingAddress?.state?.message}
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <input
              type="checkbox"
              {...register("mailingAndBillingIdentical")}
            />
            <Label
              for="mailingAndBillingIdentical"
              className={commonFormStyles.formCheckboxLabel}
            >
              Mailing and Billing Address identical?
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <button
            type="submit"
            className={`btn btn-primary ${commonFormStyles.formPullRight}`}
          >
            Update
          </button>
        </Col>
      </Row>
    </form>
  )
}
