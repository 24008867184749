import React, { useState } from "react"
import { RegistrationModel } from "./RegistrationModel"
import { Dispatch } from "@reduxjs/toolkit"
import { Field, InjectedFormProps, reduxForm } from "redux-form"
import formStyle from "../common/FormCommon.module.css"
import { Col, FormGroup, Label, Row } from "reactstrap"
import { FormInput } from "../common/form-input/FormInput"
import { FormButton } from "../common/form-button/FormButton"
import { FormCheckbox } from "../common/form-checkbox/FormCheckbox"
import states from "../common/util/states_titlecase.json"
import { registrationValidation } from "./validation"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import styles from "./RegistrationForm.module.css"
import commonFormStyles from "../../forms/common/FormCommon.module.css"
import { renderFormValidationClassNames } from "../common/FormCommonFunctions"
import { RegistrationResponse } from "../../features/registration/RegistrationResponse"

export interface RegistrationFormRef {
  HandleRegistration: (registration: RegistrationModel) => void
  HandleBackButton: () => void
}

export const RegistrationForm = ({
  registrationRef,
  orgType,
  registrationErrors,
}: {
  registrationRef: RegistrationFormRef
  orgType: "individual" | "multi-user"
  registrationErrors: string[] | undefined
}) => {
  const onSubmit = (data: RegistrationModel) => {
    registrationRef.HandleRegistration(data)
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registrationValidation),
  })

  function handleBackButtonClick() {
    registrationRef.HandleBackButton()
  }

  function RenderAccountInfoIfRequired(orgType: "individual" | "multi-user") {
    if (orgType == "multi-user") {
      return (
        <fieldset className={commonFormStyles.formFieldSet}>
          <legend>Account Information</legend>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  for="accountName"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Account Name
                </Label>
                <input
                  type="text"
                  {...register("accountName")}
                  className={renderFormValidationClassNames(errors.accountName)}
                />
                <div className="invalid-feedback">
                  {errors.accountName?.message}
                </div>
              </FormGroup>
            </Col>
          </Row>
        </fieldset>
      )
    }
  }

  function displayRegistrationError() {
    if (registrationErrors != undefined) {
      return (
        <div>
          <div className={styles.registrationError}>
            Sorry, there were problems with your registration.
          </div>
          <ul className={styles.registrationError}>
            {registrationErrors.map((value, index) => {
              return <li>{value}</li>
            })}
          </ul>
        </div>
      )
    }
  }

  return (
    <>
      <div className={commonFormStyles.edithFormHeader}>
        Register New Account
      </div>
      <p className={commonFormStyles.requiredFields} />
      {displayRegistrationError()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          {...register("accountTypeIsOrg")}
          value={orgType == "multi-user" ? "true" : "false"}
        />
        {RenderAccountInfoIfRequired(orgType)}
        <fieldset className={commonFormStyles.formFieldSet}>
          <legend>Basic Profile</legend>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  for="firstName"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Username
                </Label>
                <input
                  type="text"
                  {...register("username")}
                  className={renderFormValidationClassNames(errors.username)}
                />
                <div className="invalid-feedback">
                  {errors.username?.message}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="col-6">
              <FormGroup>
                <Label
                  for="email"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Email Address
                </Label>
                <input
                  type="text"
                  {...register("email")}
                  className={renderFormValidationClassNames(errors.email)}
                />
                <div className="invalid-feedback">{errors.email?.message}</div>
              </FormGroup>
            </Col>
            <Col className="col-6">
              <FormGroup>
                <Label
                  for="confirmEmail"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Confirm Email Address
                </Label>
                <input
                  type="text"
                  {...register("confirmEmail")}
                  className={renderFormValidationClassNames(
                    errors.confirmEmail,
                  )}
                />
                <div className="invalid-feedback">
                  {errors.confirmEmail?.message}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="col-6">
              <FormGroup>
                <Label
                  for="firstName"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  First Name
                </Label>
                <input
                  type="text"
                  autoComplete="given-name"
                  {...register("firstName")}
                  className={renderFormValidationClassNames(errors.firstName)}
                />
                <div className="invalid-feedback">
                  {errors.firstName?.message}
                </div>
              </FormGroup>
            </Col>
            <Col className="col-6">
              <FormGroup>
                <Label
                  for="lastName"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Last Name
                </Label>
                <input
                  type="text"
                  autoComplete="family-name"
                  {...register("lastName")}
                  className={renderFormValidationClassNames(errors.lastName)}
                />
                <div className="invalid-feedback">
                  {errors.lastName?.message}
                </div>
              </FormGroup>
            </Col>
          </Row>
        </fieldset>
        <fieldset className={commonFormStyles.formFieldSet}>
          <legend>Password</legend>
          <Row>
            <Col>
              <ul>
                <li>Passwords should be six(6) or more characters.</li>
                <li>Include one or more numbers or special characters.</li>
              </ul>
            </Col>
            <Col>
              <ul>
                <li>Include one or more upper case characters.</li>
                <li>Include one or more lower case characters.</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  for="password"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Password
                </Label>
                <input
                  type="password"
                  {...register("password")}
                  className={renderFormValidationClassNames(errors.password)}
                />
                <div className="invalid-feedback">
                  {errors.password?.message}
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  for="confirmPassword"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Confirm Password
                </Label>
                <input
                  type="password"
                  {...register("confirmPassword")}
                  className={renderFormValidationClassNames(
                    errors.confirmPassword,
                  )}
                />
                <div className="invalid-feedback">
                  {errors.confirmPassword?.message}
                </div>
              </FormGroup>
            </Col>
          </Row>
        </fieldset>
        <fieldset className={commonFormStyles.formFieldSet}>
          <legend>Address Information</legend>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  for="mailingAddress1"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Mailing Address 1
                </Label>
                <input
                  type="text"
                  {...register("mailingAddress1")}
                  className={renderFormValidationClassNames(
                    errors.mailingAddress1,
                  )}
                />
                <div className="invalid-feedback">
                  {errors.mailingAddress1?.message}
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="mailingAddress2">Mailing Address 2</Label>
                <input
                  type="text"
                  {...register("mailingAddress2")}
                  className={renderFormValidationClassNames(
                    errors.mailingAddress2,
                  )}
                />
                <div className="invalid-feedback">
                  {errors.mailingAddress2?.message}
                </div>
              </FormGroup>
              <FormGroup>
                <Label
                  for="mailingAddress.address2"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Mailing City
                </Label>
                <input
                  type="text"
                  {...register("mailingCity")}
                  className={renderFormValidationClassNames(errors.mailingCity)}
                />
                <div className="invalid-feedback">
                  {errors.mailingCity?.message}
                </div>
              </FormGroup>
              <FormGroup>
                <Label
                  for="mailingZipCode"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Mailing Zip Code
                </Label>
                <input
                  type="text"
                  {...register("mailingZipCode")}
                  className={renderFormValidationClassNames(
                    errors.mailingZipCode,
                  )}
                />
                <div className="invalid-feedback">
                  {errors.mailingZipCode?.message}
                </div>
              </FormGroup>
              <FormGroup>
                <Label
                  for="state"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Mailing State
                </Label>
                <div className="inputWrapper">
                  <select
                    placeholder="Select Your State"
                    autoComplete="address-level1"
                    {...register("mailingState")}
                    className={renderFormValidationClassNames(
                      errors.mailingState,
                      ["form-select", "state-select"],
                    )}
                  >
                    <option value="">Select a State</option>
                    {states.map(function (item, i) {
                      return (
                        <option key={i} value={item.abbreviation}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  <div className="invalid-feedback">
                    {errors.mailingState?.message}
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  for="billingAddress1"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Billing Address 1
                </Label>
                <input
                  type="text"
                  {...register("billingAddress1")}
                  className={renderFormValidationClassNames(
                    errors.billingAddress1,
                  )}
                  disabled={watch("mailingAndBillingIdentical")}
                />
                <div className="invalid-feedback">
                  {errors.billingAddress1?.message}
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="billingAddress2">Billing Address 2</Label>
                <input
                  type="text"
                  {...register("billingAddress2")}
                  className={renderFormValidationClassNames(
                    errors.billingAddress2,
                  )}
                  disabled={watch("mailingAndBillingIdentical")}
                />
                <div className="invalid-feedback">
                  {errors.billingAddress2?.message}
                </div>
              </FormGroup>
              <FormGroup>
                <Label
                  for="billingCity"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Billing City
                </Label>
                <input
                  type="text"
                  {...register("billingCity")}
                  className={renderFormValidationClassNames(errors.billingCity)}
                  disabled={watch("mailingAndBillingIdentical")}
                />
                <div className="invalid-feedback">
                  {errors.billingCity?.message}
                </div>
              </FormGroup>
              <FormGroup>
                <Label
                  for="billingZipCode"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Billing Zip Code
                </Label>
                <input
                  type="text"
                  {...register("billingZipCode")}
                  className={renderFormValidationClassNames(
                    errors.billingZipCode,
                  )}
                  disabled={watch("mailingAndBillingIdentical")}
                />
                <div className="invalid-feedback">
                  {errors.billingZipCode?.message}
                </div>
              </FormGroup>
              <FormGroup>
                <Label
                  for="state"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Billing State
                </Label>
                <div className="inputWrapper">
                  <select
                    placeholder="Select Your State"
                    autoComplete="address-level1"
                    {...register("billingState")}
                    className={renderFormValidationClassNames(
                      errors.billingState,
                      ["form-select", "state-select"],
                    )}
                    disabled={watch("mailingAndBillingIdentical")}
                  >
                    <option value="">Select a State</option>
                    {states.map(function (item, i) {
                      return (
                        <option key={i} value={item.abbreviation}>
                          {item.name}
                        </option>
                      )
                    })}
                    disabled={watch("mailingAndBillingIdentical")}
                  </select>
                  <div className="invalid-feedback">
                    {errors.billingState?.message}
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <input
                  type="checkbox"
                  {...register("mailingAndBillingIdentical")}
                />
                <Label
                  for="mailingAndBillingIdentical"
                  className={commonFormStyles.formCheckboxLabel}
                >
                  Mailing and Billing Address identical?
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </fieldset>
        <fieldset className={commonFormStyles.formFieldSet}>
          <legend>Phone Number</legend>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  for="phoneNumber"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Phone Number
                </Label>
                <input
                  type="text"
                  {...register("phoneNumber")}
                  className={renderFormValidationClassNames(errors.phoneNumber)}
                />
                <div className="invalid-feedback">
                  {errors.phoneNumber?.message}
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  for="phoneNumberTypeId"
                  className={commonFormStyles.requiredFieldLabel}
                >
                  Phone Number Type
                </Label>
                <div className="inputWrapper">
                  <select
                    placeholder="Select your phone type"
                    {...register("phoneNumberTypeId")}
                    className={renderFormValidationClassNames(
                      errors.phoneNumberTypeId,
                      ["form-select", "state-select"],
                    )}
                  >
                    <option value="1">Mobile</option>
                    <option value="2">Landline</option>
                  </select>
                  <div className="invalid-feedback">
                    {errors.phoneNumberTypeId?.message}
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <input type="checkbox" {...register("isSmsOkay")} />
                <Label
                  for="isSmsOkay"
                  className={commonFormStyles.formCheckboxLabel}
                >
                  Are SMS messages okay at this number?
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </fieldset>
        <fieldset className={commonFormStyles.formFieldSet}>
          <legend>Agreements</legend>
          <Row>
            <Col>
              <FormGroup>
                <input
                  type="checkbox"
                  {...register("agreeToEula")}
                  className={
                    errors.agreeToEula != undefined ? "is-invalid" : ""
                  }
                />
                <Label
                  for="agreeToPrivacyAgreement"
                  className={commonFormStyles.formCheckboxLabel}
                >
                  I agree to the End User Licence Agreement
                </Label>
                <div className="invalid-feedback">
                  {errors.agreeToEula?.message}
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <input
                  type="checkbox"
                  {...register("agreeToPrivacyAgreement")}
                  className={
                    errors.agreeToEula != undefined ? "is-invalid" : ""
                  }
                />
                <Label
                  for="agreeToPrivacyAgreement"
                  className={commonFormStyles.formCheckboxLabel}
                >
                  I agree to the Privacy Policy
                </Label>
                <div className="invalid-feedback">
                  {errors.agreeToPrivacyAgreement?.message}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <a href="/eula" target="_blank">
                Review our End User Licence Agreement
              </a>
            </Col>
            <Col>
              <a href="/privacy" target="_blank">
                Review our Privacy Policy
              </a>
            </Col>
          </Row>
        </fieldset>
        <Row>
          <Col>
            <button
              className="btn btn-secondary"
              onClick={handleBackButtonClick}
            >
              Go Back to Account Type Selection
            </button>
          </Col>
          <Col>
            <button
              type="submit"
              className={`btn btn-primary ${commonFormStyles.formPullRight}`}
            >
              Register Account
            </button>
          </Col>
        </Row>
      </form>
    </>
  )
}
