import { ExternalActivityModel } from "../../activity-parts/external-activity/ExternalActivityModel"
import { ExternalActivity } from "../../activity-parts/external-activity/ExternalActivity"
import { InternalActivityDetailModel } from "./InternalActivityDetailModel"
import React from "react"
import { ActivityModel } from "./ActivityModel"
import { InternalActivity } from "../../activity-parts/internal-activity/InternalActivity"
import { ActivityResponseModel } from "../../ActivityResponseModel"

export interface ActivityProperties {
  activity: ActivityModel
  responses: ActivityResponseModel[]
}

export function Activity(props: ActivityProperties) {
  function renderActivity(activity: ActivityModel) {
    function renderExternalActivity(
      activityContentItemId: string,
      activityContentVersionId: string,
      externalActivity: ExternalActivityModel,
    ) {
      return (
        <ExternalActivity
          externalActivity={externalActivity}
        ></ExternalActivity>
      )
    }

    function renderInternalActivity(
      activityContentItemId: string,
      activityContentVersionId: string,
      internalActivity: InternalActivityDetailModel,
    ) {
      return (
        <InternalActivity
          activityContentItemId={activityContentItemId}
          activityContentItemVersionId={activityContentVersionId}
          internalActivityView={internalActivity}
          responses={props.responses}
        ></InternalActivity>
      )
    }

    switch (activity.activity.activityType) {
      case "ExternalActivity":
        return renderExternalActivity(
          activity.contentItemId,
          activity.contentItemVersionId,
          activity.activity.externalActivity,
        )
      case "InternalActivity":
        return renderInternalActivity(
          activity.contentItemId,
          activity.contentItemVersionId,
          activity.activity.internalActivity,
        )
    }
  }

  return (
    <div className="modulePartContent">{renderActivity(props.activity)}</div>
  )
}
