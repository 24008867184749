import commonFormStyles from "../../forms/common/FormCommon.module.css"

export interface ThankYouProperties {
  receiptUri: string
}

export function ThankYou(props: ThankYouProperties) {
  return (
    <>
      <div className={commonFormStyles.edithFormHeader}>Thank You!</div>
      <div>
        Thank you so much for upgrading! You can view your receipt{" "}
        <a href={props.receiptUri}>here</a>.
      </div>
    </>
  )
}
